(function () {

    'use strict';

    module.exports = mnWebSocket;

    const {Subject, BehaviorSubject} = require("rxjs");

    const wsConnection = require("../ws/wsConnection");
    const mnWebSocketService = require("../ws/mnWebSocketService");

    mnWebSocket.$inject = [];

    function mnWebSocket() {
        let self = this;
        let exposeIt = false, to_debug = false;
        let connection = null;

        let options = {
            log: log,
            warn: warn,
            error: error,
            connectionSubject: new Subject(),
            subject: new BehaviorSubject(null),
        }

        self.$get = _.noop;
        self.debug = debug;
        self.config = config;
        self.expose = expose;
        self.connect = connect;

        function log(msg) {
            if (to_debug) console.info(msg);
        }

        function warn(msg) {
            if (to_debug) console.warn(msg);
        }

        function error(msg) {
            if (to_debug) console.error(msg);
        }

        function debug(_to_debug) {
            to_debug = _.isUndefined(_to_debug) ? true : _to_debug;
        }

        function expose() {
            exposeIt = true;
        }

        function config(_protocol, _host, _port) {
            if (!_.isUndefined(_protocol)) ws.protocol = _protocol;
            if (!_.isUndefined(_host)) ws.host = _host;
            if (!_.isUndefined(_port)) ws.port = _port;
        }

        function connect() {
            options.debug = to_debug;
            options.exposeIt = exposeIt;

            connection = new wsConnection(options);
            self.$get = mnWebSocketService(connection, options);
        }
    }
})();
