(function () {

    'use strict';

    class AuthFormCtrl {
        constructor($auth, authService, $mdToast, $translate, $q, system, moment) {
            this.$q = $q;
            this.$auth = $auth;
            this.toast = $mdToast;
            this.authService = authService;
            this.moment = moment;

            this.user = {};
            this.focus = false;

            this.licencedFor = system['licenced_for'];
            this.accounts = system['allowed_accounts'];

            this.simpleToast = $mdToast.simple()
                .textContent($translate.instant('log_error'))
                .position("bottom right")
                .hideDelay(1500);
        }

        static get $inject() {
            return ['$auth', 'authService', '$mdToast', '$translate', '$q', 'system', 'moment'];
        }

        $onInit() {
            this.showPassword = false;
            this.focus = true;
            this.currentYear = this.moment().format("YYYY");
        }

        submit() {
            const deferred = this.$q.defer();
            //result => this.success(result).then(deferred.resolve, () => this.error()), () => this.error()

            this.promise = this.$auth
                .login(this.user)
                .then(result => {
                    if (_.isNil(result.data['account'])) {
                        this.error(deferred);
                    } else {
                        this.authService.handleUser(result.data['account'])
                            .then(() => deferred.resolve(true), () => this.error(deferred));
                    }
                }, () => this.error(deferred));

            return deferred.promise;
        }

        error(deferred) {
            this.toast.show(this.simpleToast);
            deferred.reject(null);
        }
    }

    module.exports = AuthFormCtrl;

})();
