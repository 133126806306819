/**
 * Created by Hp on 12/05/2017.
 */
(function () {

    'use strict';

    class CamDocumentsListDialogCtrl {
        constructor($mdDialog) {
            this.$mdDialog = $mdDialog;
            this.selected = [];
            this.cancel = this.$mdDialog.cancel;
            this.addItem = this.addItem.bind(this);

        }

        static get $inject() {
            return ["$mdDialog"];
        }

        $onInit() {
            this.actions = {
                single: [],
                multiple: this.editTpl ? [
                    {
                        icon: 'mdi-plus',
                        label: 'add',
                        resource: this.resource,
                        action: 'create',
                        behavior: 'remove',
                        method: this.addItem,
                        isStatic: true
                    }] : []
            };
        }

        addSelection() {
            this.$mdDialog.hide(this.selected);
        }

        addItem(ev) {
            const dialogConfig = _.assign({}, ITEM_FORM_DIALOG, {
                targetEvent: ev,
                template: this.editTpl,
                locals: {apiUrl: this.apiUrl},
            });
            this.$mdDialog.show(dialogConfig).then(this.reset);
        }
    }

    class ItemEditDialogCtrl {
        constructor($mdDialog, $http) {
            this.http = $http;
            this.dialog = $mdDialog;
            this.model = this.model || {price: 0};
        }

        static get $inject() {
            return ["$mdDialog", "$http"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.http.post(this.apiUrl, this.model)
                .then(response => this.dialog.hide(response.data));
        }
    }


    const ITEM_FORM_DIALOG = {
        locals: {},
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ItemEditDialogCtrl,
        focusOnOpen: false
    };

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: CamDocumentsListDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/cam-document-list-dialog.html"),
    };
})();