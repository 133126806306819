/**
 * Created by Hp on 03/08/2017.
 */

(function () {
    'use strict';
    module.exports = ExpenseFormCtrl;

    ExpenseFormCtrl.$inject = ["dragulaService", "$stateParams", "$q", "patientService", "$translate", "system", "$mdDialog", 'billingService', "saleService", "$state", "$mdToast", "$scope", "listService", "configService"];

    function ExpenseFormCtrl(dragulaService, $stateParams, $q, patientService, $translate, system, $mdDialog, billingService, saleService, $state, $mdToast, $scope, listService, configService) {
        let vm = this;
        let currentExpenseId = $stateParams['expenseId'];

        vm.$onInit = init;
        let dateFormat = system['date_format'].js;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.onPaginate = onPaginate;


        vm.removeLine = removeLine;
        vm.expenseTotal = expenseTotal;
        vm.getSubTotal = getSubTotal;
        vm.addEmptyLine = addEmptyLine;
        vm.validateExpense = validateExpense;
        vm.invalidateExpense = invalidateExpense;
        vm.removeExpense = removeExpense;
        vm.cancel = goBack;

        vm.addExpense = addExpense;
        vm.submit = submit;
        vm.prePrint = prePrint;
        $scope.$watch('vm.expense.is_valid', readOnlyExpense);
        let bag = "line-bag";

        function init() {


            vm.selectedLine = null;
            vm.expense = {};
            vm.lines = [];
            vm.mnModel = 'Expense';
            vm.vatList = [];
            vm.paymentModeList = [];
            vm.expenseCategorieList = [];
            vm.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};
            vm.is_new = !_.isNil(currentExpenseId) ? false : true;
            vm.query = {
                limit: 5,
                page: 1,
                order: "-id"
            };
            vm.options = [ 5,10, 20, 100];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            };

            vm.promises = [listService.list('ExpenseCategory'), listService.list('Tva'), listService.list('PaymentModeType'),
                configService.get(["price_mode"], true)];

            if (!_.isNil(currentExpenseId)) {
                vm.promises.push(billingService.getExpense(currentExpenseId));
            }
            else {
                vm.promise = null;
                vm.expense = {
                    date: moment().format(dateFormat),
                    lines: [],
                    // price_mode: 'HT'
                };
                getData();
            }
            vm.promise = $q.all(vm.promises).then(success);
            initDrag();

            function success(data) {
                vm.expenseCategorieList = data[0];
                vm.vatList = data[1];
                vm.paymentModeList = data[2];
                if (!_.isNil(currentExpenseId)) vm.expense = data[4];
                else {
                    vm.expense.price_mode = data[3];
                }
            }
        }

        function readOnlyExpense() {
            vm.readOnly = (vm.expense && vm.expense.is_valid) ? vm.expense.is_valid : false;
        }

        function removePatient() {
            vm.expense.patient = null;
        }

        function initDrag() {
            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
            let drake = dragulaService.find($scope, bag).drake;
            drake.on("dragend", _.mnDelay(function () {
            }, 400));
        }

        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id, false)
                .then(doneCallback);

            function doneCallback(data) {
                vm.expense.patient = data;
            }
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function getData() {
            if (vm.expense.lines.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function removeLine(index) {
            vm.expense.lines.splice((vm.query.page - 1) * vm.query.limit + index, 1);
        }

        function addEmptyLine() {
            vm.expense.lines.push({is_comment: true});
        }

        function expenseTotal() {

            vm.expense.taxed_amount = _.chain(vm.expense.lines).filter(['is_comment', false]).sumBy((line) => {
                return getLinePrice(line)
            }).value();
            return vm.expense.taxed_amount
        }


        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                vm.promise = billingService.saveExpense(vm.expense).then(success);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) {
                    goBack();
                }
                else {
                    saveSuccess(data);
                }
            }
        }

        function saveSuccess(data) {
            vm.expense = data;
            if (_.isNil(currentExpenseId)) $state.go('app.billing.expense-form', {expenseId: data.id}, {location: 'replace'});
            vm.prevent_invalidate = false;
            vm.prevent_validate = false;
        }


        function validateExpense() {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('validation_warning'))
                        .ariaLabel('validation_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    vm.prevent_validate = true;
                    billingService.updateBillingDocument(vm.expense.id,'expense', {is_valid: true}).then(saveSuccess);

                })
            }
        }

        function invalidateExpense() {
            if (!vm.prevent_invalidate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('invalidate_warning'))
                        .ariaLabel('invalidate_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    vm.prevent_invalidate = true;
                    vm.promise = billingService.invalidateExpense(vm.expense.id).then(doneCallBack);
                });
            }

            function doneCallBack(data) {
                $state.go('app.expense-form', {expenseId: data.id}, {location: 'replace'});
                vm.expense = data;
            }
        }

        function removeExpense(expense) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                if (!vm.prevent_remove) {
                    vm.prevent_remove = true;
                    vm.promise = billingService.deleteExpense(expense.id).then(success);
                }
            });

            function success(data) {
                goBack();
            }
        }

        function goBack() {
            window.history.back();
        }

        function prePrint() {
            return billingService.saveExpense(vm.expense);
        }

        function getSubTotal(line) {
            return (line.price || 0.0) * (line.qte || 0.0);
        }

        function addExpense() {
            vm.expense.lines.push({
                category: null,
                short_desc: "",
                vat: null,
                amount: 0.0,
                payment_mode: null,
                is_comment: false,
            })
        }


        function getLinePrice(line) {
            if (vm.expense.price_mode == 'TTC') {
                return line.amount;
            }
            else return !_.isNil(line.vat) ? line.amount + (line.amount * parseInt(line.vat.value) / 100) : line.amount;
        }
    }
})();
