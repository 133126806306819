(function () {
    'use strict';

    class QConfig {
        constructor($qProvider) {
            //$qProvider.errorOnUnhandledRejections(false);
        }

        static create() {
            return new QConfig(...arguments);
        }
    }

    QConfig.create.$inject = ['$qProvider'];

    module.exports = QConfig.create;

})();
