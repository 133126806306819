/**
 * Created by Hp on 04/05/2017.
 */
(function () {

    'use strict';

    var ContractRenewDialog = {
        controller: ContractRenewDialogCtrl,
        controllerAs: "vm",
        template: require("billing/views/contract-renew.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        focusOnOpen: false

    };

    ContractRenewDialogCtrl.$inject = ["$mdDialog", "$translate", "system"];

    function ContractRenewDialogCtrl($mdDialog, $translate, system) {
        let vm = this;
        vm.$onInit = init;

        let dateFormat = system['date_format'].js;

        vm.cancel = $mdDialog.cancel;
        vm.save = save;
        vm.onDetailsPaginate = onDetailsPaginate;
        vm.getAmount = getAmount;
        vm.getSubTotal = getSubTotal;
        vm.selectArticle = selectArticle;
        vm.removeLine = removeLine;

        vm.detailsQuery = {
            limit: 10,
            page: 1,
            order: "-end_at"
        }
        vm.options = [10, 20, 100];

        vm.paginationLabel = {
            page: $translate['instant']('page'),
            rowsPerPage: $translate['instant']('rowsPerPage'),
            of: $translate['instant']('of')
        }

        function init() {
            vm.start_at = vm.PrevEndDate || moment().format(dateFormat);
            vm.minDate = moment(vm.PrevEndDate, dateFormat).toDate();
            vm.instance = !vm.instance ? {details: []} : vm.instance;
        }

        function save() {
            $mdDialog.hide(_.assignIn(vm.instance, {start_at: vm.start_at, operator: vm.operator}));
        }

        function onDetailsPaginate(page, limit) {
            vm.detailsQuery = _.assign(vm.detailsQuery, {page: page, limit: limit});
        }

        function getAmount(instance) {
            return _.get(instance, 'details') ? vm.instance.taxed_amount = _.sumBy(instance.details, function (line) {
                return line.sub_total;
            }) : 0;
        }

        function getSubTotal(line) {
            return line.sub_total = _.get(line, 'price', 0) * _.get(line, 'qte', 0);
        }


        function selectArticle(instance, ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then((data) => {
                _.forEach(data, function (article) {
                    if (_.isNil(_.find(instance.details, {code: article.code}))) {
                        instance.details.push({
                            code: article.code,
                            description: article.short_desc,
                            qte: 1,
                            price: _.get(article, 'sale_price', 0),
                            article: _.cloneDeep(article)
                        });
                    }
                });
            });
        }

        function removeLine(instance, index) {
            instance.details.splice((vm.detailsQuery.page - 1) * vm.detailsQuery.limit + index, 1);
        }


    }

    module.exports = ContractRenewDialog;

})();