(function () {

    'use strict';

    class ServiceDialogCtrl {
        constructor($mdDialog, $http) {
            this.http = $http;
            this.dialog = $mdDialog;

            this.model = this.model || {};
        }

        static get $inject() {
            return ["$mdDialog", "$http"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            const url = `/api/service/${this.model.id ? this.model.id + '/' : ''}`

            this.http.post(url, this.model)
                .then(response => this.dialog.hide(response.data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ServiceDialogCtrl,
        template: require("../views/service-dialog.tpl.html"),
    };

})();
