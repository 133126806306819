(function () {

    'use strict';

    function tpl() {
        return [
            '<md-button md-no-ink class="md-icon-button" ng-click="vm.logout()" aria-label="logout">',
            '   <md-icon md-font-icon="mdi-power" md-font-set="mdi"></md-icon>',
            '   <md-tooltip md-direction="bottom" md-delay="150">',
            '       <span translate-once="logout"></span>',
            '   </md-tooltip>',
            '</md-button>'
        ].join('');
    }

    class LogoutCtrl {
        constructor(authService) {
            this.logout = () => authService.logout();
        }

        static get $inject() {
            return ["authService"];
        }
    }

    module.exports = {
        template: tpl,
        controllerAs: "vm",
        controller: LogoutCtrl,
    };

})();
