/**
 * Created by BETALOS on 26/02/2016.
 */
(function () {
    'use strict';

    const INVALID_CLASS = 'md-datepicker-invalid';

    class Date {
        constructor(moment, system, $$mdDateUtil) {
            this.priority = 1;
            this.restrict = "A"
            this.require = ['^ngModel', '^mdDatepicker'];

            this.mdDateUtil = $$mdDateUtil;

            this.moment = moment;
            this.dateFormat = system['date_format'].js;
        }

        static create() {
            return new Date(...arguments);
        }

        link(scope, element, attrs, ctrl) {
            //ctrl[0].$overrideModelOptions({'timezone': 'Europe/Paris'})
            let mode = null;
            if (ctrl[1].mode === "year") {
                mode = 'year';
                ctrl[1].$attrs['mdMode'] = "month";
            } else if (ctrl[1].mode === "month") {
                mode = 'month';
            }
            this.patchNgModelCtrl(ctrl[0], ctrl[1], mode, scope);
            this.patchDatePickerCtrl(ctrl[1]);

            $('.md-datepicker-triangle-button', element).attr('tabindex', -1);
            element.find(".md-datepicker-input").on("change", (ev) => this.handler(ev.currentTarget, ctrl[1], scope));
        }

        patchNgModelCtrl(ngModelCtrl, mdDatepickerCtrl, mode, scope) {
            ngModelCtrl.$parsers = _.castArray(input => this.parser(input, mdDatepickerCtrl, mode, scope));
            ngModelCtrl.$formatters = _.castArray(model => this.formatter(model, mdDatepickerCtrl));
        }

        formatter(valueFromModel, mdDatepickerCtrl) {
            let value = null;

            if (_.isString(valueFromModel)) {
                value = this.moment.utc(valueFromModel, this.dateFormat).toDate();
            } else if (this.moment.isMoment(valueFromModel)) {
                value = valueFromModel.toDate();
            } else if (_.isDate(valueFromModel)) value = valueFromModel;

            mdDatepickerCtrl.onExternalChange(value);

            return value;
        }

        parser(valueFromInput, mdDatepickerCtrl, mode, scope) {
            if (valueFromInput) {
                let format = null;
                if (_.isString(valueFromInput)) {
                    format = valueFromInput.includes('-') ? "YYYY-MM-DD" : this.dateFormat;
                }
                // @TODO TO FIX and remove setTimeout and find better way (amine)
                setTimeout(() => {
                    scope.$applyAsync(() => mdDatepickerCtrl.ngModelCtrl.$setPristine(true));
                }, 0);

                let value = this.moment(valueFromInput, format);
                switch (mode) {
                    case "year":
                        value = this.moment(valueFromInput, format).startOf('year');
                        break;
                    case "month":
                        value = this.moment(valueFromInput, format).startOf('month');
                        break;
                }

                return value.format(this.dateFormat);
            } else return null;
        }

        patchDatePickerCtrl(mdDatepickerCtrl) {
            const moment = this.moment;
            const dateFormat = this.dateFormat;

            mdDatepickerCtrl.updateErrorState = function (opt_date) {
                // fix minDate/maxDate issue this.date somehow is one day behind
                let date = opt_date || moment(this.ngModelCtrl.$modelValue, dateFormat).toDate();

                // Clear any existing errors to get rid of anything that's no longer relevant.
                this.clearErrorState();

                if (this.dateUtil.isValidDate(date)) {
                    // Force all dates to midnight in order to ignore the time portion.
                    date = this.dateUtil.createDateAtMidnight(date);

                    if (this.dateUtil.isValidDate(this.minDate)) {
                        let minDate = this.dateUtil.createDateAtMidnight(this.minDate);
                        this.ngModelCtrl.$setValidity('mindate', date >= minDate);
                    }

                    if (this.dateUtil.isValidDate(this.maxDate)) {
                        let maxDate = this.dateUtil.createDateAtMidnight(this.maxDate);
                        this.ngModelCtrl.$setValidity('maxdate', date <= maxDate);
                    }

                    if (angular.isFunction(this.dateFilter)) {
                        this.ngModelCtrl.$setValidity('filtered', this.dateFilter(date));
                    }
                }

                // TODO check the method version
                /*else {
                    // The date is seen as "not a valid date" if there is *something* set
                    // (i.e.., not null or undefined), but that something isn't a valid date.
                    this.ngModelCtrl.$setValidity('valid', date == null);
                }*/

                angular.element(this.inputContainer).toggleClass(INVALID_CLASS, !this.ngModelCtrl.$valid);
            }
        }

        handler(node, mdDatepickerCtrl) {
            let _d;
            let $input = $(node);

            const inputVal = $input.val();

            switch ($input.val().length) {
                case 2:
                    _d = this.moment(inputVal, "YY");
                    break;
                case 4:
                    _d = this.moment(inputVal, "MMYY");
                    if (_d.isValid() && parseInt(_d.format("YYYY")) > parseInt(this.moment().format("YYYY"))) {
                        _d.subtract(100, "years");
                    } else _d = this.moment(inputVal, "YYYY");

                    break;
                case 6:
                    _d = this.moment(inputVal, "DDMMYY");
                    if (parseInt(_d.format("YYYY")) > parseInt(this.moment().format("YYYY"))) {
                        _d.subtract(100, "years");
                    }
                    break;
                case 8:
                    _d = this.moment(inputVal, "DDMMYYYY");
                    break;
                default :
                    _d = this.moment(inputVal, this.dateFormat);
                    if (!_d.isValid()) _d = false;
            }

            const value = (_d === false || !_d.isValid()) ? null : _d.toDate();

            mdDatepickerCtrl.setModelValue(value);
            mdDatepickerCtrl.onExternalChange(value);
        }
    }

    Date.create.$inject = ["moment", "system", "$$mdDateUtil"];

    module.exports = Date.create;

})();
