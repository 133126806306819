/**
 * Created by amine on 26/03/2017.
 */
(function () {
    'use strict';

    const {BehaviorSubject, take} = require("rxjs");

    class Print {
        constructor(printService, $translate) {
            this.require = [];
            this.restrict = "A";
            this.scope = {
                prePrint: "&?",
                item: "=mnPrintLink"
            };

            this.$translate = $translate;
            this.printService = printService;
        }

        static create() {
            return new Print(...arguments);
        }

        link($scope, $element) {
            this.gotModel = new BehaviorSubject(this.getModel($scope));
            this.$unwatch = $scope.$watch("item.model", () => {
                let model = this.getModel($scope);
                this.gotModel.next(model);
            });

            $element.on("click", (ev) => {
                if (_.isNil($scope.prePrint)) this.print($scope);
                else {
                    $scope.prePrint({$event: ev})
                        .then(data => {
                            this.gotModel
                                .pipe(take(1))
                                .subscribe({
                                    next: () => {
                                        if ($scope.item.query || this.getModel($scope)) this.print($scope, data);
                                    },
                                    onerror: () => _.noop()
                                });
                        }, _.noop);
                }
            });

            $scope.$on('$destroy', () => {
                this.$unwatch();
                this.gotModel.complete();
            });
        }

        getModel(scope) {
            return _.get(scope, "item.model", null);
        }

        print($scope, data) {
            if ($scope.item.query) this.customPrint($scope, data);
            else if ($scope.item.pdf) this.pdfExport($scope, data);
            else this.modelPrint($scope);
        }

        pdfExport($scope, data) {
            if (_.isNil($scope.item.group)) return false;

            let titleKey = _.snakeCase(`pdf_title_${$scope.item.group}`);
            let title = this.$translate.instant(titleKey);

            this.printService.printItemToPdf(
                $scope.item.group, $scope.item.model, $scope.item.uid, data, $scope.item.viewSet, title
            );
        }

        customPrint($scope, data) {
            if (_.isNil($scope.item.group)) return false;

            else this.printService.customPrint(
                data, $scope.item.group, $scope.item.uid, $scope.item.viewSet, $scope.item.view
            );
        }

        modelPrint($scope) {
            if (_.isNil($scope.item.group)) return false;

            else this.printService.printItem(
                $scope.item.group, $scope.item.model, $scope.item.uid, null, $scope.item.viewSet
            );
        }
    }

    Print.create.$inject = ['printService', '$translate'];

    module.exports = Print.create;

})();
