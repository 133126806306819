/**
 * Created by amine on 01/12/2018.
 */


(function () {
    'use strict';

    class InterventionStepsCtrl {
        constructor($http, $scope, $mdDialog, $translate) {
            this.$http = $http;
            this.$scope = $scope;
            this.dialog = $mdDialog;
            this.$translate = $translate;

        }

        static get $inject() {
            return ["$http", "$scope", "$mdDialog", "$translate"];
        }

        $onInit() {
            this.options = [5, 10, 20];
            this.Query = {
                limit: 10,
                page: 1,
            };
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
        }

        onPaginate(page, limit) {
            this.Query = _.assign(this.Query, {page: page, limit: limit});
            if (this.model.length < ((this.Query.page - 1) * this.Query.limit)) {
                this.Query.page = 1;
            }
        }

        selectItems() {
            this.dialog.show(_.assign(require('cam/dialogs/cam-document-list-dialog'), {
                locals: {
                    search: {is_deleted: {$ne: true}},
                    headerIcon: 'mdi-file-check',
                    headerLabel: 'intervention_static_step_list',
                    tableModel: 'cam.InterventionStaticStep',
                    editTpl: require('../views/intervention-step-edit-dialog.html'),
                    apiUrl: '/api/intervention-static-step/',
                    resource:'intervention-static-step'
                }
            })).then(data => {
                this.model = _.concat(this.model, _.map(data, (e) => {
                    return _.assignIn({checked: false}, _.pick(e, ['code', 'description']),
                        {static_step: _.pick(e, ['id', 'code', 'description'])})
                }));
            });
        }

        addItem() {
            this.model.push({checked: false, code: null, description: null});
            this.focus = this.model.length() - 1;
        }

        removeItem(idx) {
            this.model.splice(idx, 1);
        }
    }

    module.exports = {
        controllerAs: "vm",
        bindings: {
            model: "=mnModel",
            isPlanning: "=isPlanning",
            readOnly: "=readOnly"
        },
        template: require('cam/views/intervention-steps.html'),
        controller: InterventionStepsCtrl,
    };

})();
