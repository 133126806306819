(function () {
    'use strict';

    class EquipmentFormCtrl {
        constructor($state, $mdToast, $translate, printService, equipmentService, $stateParams, $mdDialog, camConfigService) {
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.equipmentService = equipmentService;
            this.$mdToast = $mdToast;
            this.$stateParams = $stateParams;
            this.$mdDialog = $mdDialog;
            this.camConfigService = camConfigService;

            this.currentModel = this.$stateParams['modelId'];

        }

        static get $inject() {
            return ["$state", "$mdToast", "$translate", "printService", "equipmentService", "$stateParams", "$mdDialog", "camConfigService"];
        }

        $onInit() {
            this.file = null;
            this.prevent_save = false;
            this.editorOpts = {};
            this.options = [5, 10, 20];
            this.sparePartsQuery = {
                limit: 5,
                page: 1,
                order: "+code"
            };
            this.ExploitationQuery = {
                limit: 5,
                page: 1,
                order: "-id"
            };
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
            this.exploitation_lines = [];
            if (_.isNil(this.currentModel)) {
                this.promise = null;
                this.model = {
                    spare_parts: [], owner_warranty_duration_unit: 'years',
                    distributor_warranty_duration_unit: 'years', owner_warranty_duration: 1,
                    distributor_warranty_duration: 1
                };
            } else {
                this.promise = this.equipmentService.getEquipment(this.currentModel).then((data) => {
                    this.model = data;
                });
                this.loadExploitations()
            }
        }

        submit(with_cancel = false) {
            if (!this.prevent_save) {
                this.prevent_save = true;
                this.equipmentService.saveEquipment(this.model, this.file).then((data) => {
                    this.save_success(data, with_cancel);
                }, (err) => {
                    this.prevent_save = false
                });
            }
        }

        save_success(data, with_cancel) {
            this.prevent_save = false;
            if (with_cancel) this.$state.go('app.cam.equipment');
            else {
                this.model = data;
                this.$state.go('app.cam.equipment-form', {modelId: data.id}, {replace: true});
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant(
                        _.isNil(this.currentModel) ? this.$translate.instant('save_success') : this.$translate.instant('edit_success')))
                    .position("bottom left")
                    .hideDelay(1500));
            }
        }

        delete() {
            this.camConfigService.checkBeforeDeleteItem("cam.Equipment", this.currentModel).then(res => {
                if (!res) {
                    this.promise = this.equipmentService.deleteEquipment(this.currentModel).then((data) => {
                        this.$state.go('app.cam.equipment');
                    }, this.deleteImpossible.bind(this));
                } else {
                    this.deleteImpossible();
                }
            }, this.deleteImpossible.bind(this));
        }

        deleteImpossible() {
            this.$mdToast.show(this.$mdToast.simple()
                .textContent(this.$translate.instant(this.$translate.instant('impossible_delete')))
                .position("bottom left")
                .hideDelay(1500));
        }

        onPaginateSpareParts(page, limit) {
            this.sparePartsQuery = _.assign(this.sparePartsQuery, {page: page, limit: limit});
            if (this.model.spare_parts.length < ((this.sparePartsQuery.page - 1) * this.sparePartsQuery.limit)) {
                this.sparePartsQuery.page = 1;
            }
        }

        removeItem(idx) {
            this.model.spare_parts.splice((this.sparePartsQuery.page - 1) * this.sparePartsQuery.limit + idx, 1);
        }

        selectSpareParts(ev) {
            this.$mdDialog.show(_.assign(require('cam/dialogs/cam-document-list-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {is_deleted: {$ne: true}},
                    headerIcon: 'mdi-checkbox-multiple-blank-circle',
                    headerLabel: 'spare_parts',
                    tableModel: 'cam.SparePart',
                    editTpl: require('cam/views/spare-part-dialog.html'),
                    apiUrl: '/api/spare-part/',
                    resource: 'spare-part'
                }
            })).then(data => {
                this.model.spare_parts = _.concat(this.model.spare_parts, data);
            });
        }

        resetPicture(ev) {
            ev.stopPropagation();
            this.file = null;
            this.model.picture = null;
        }

        loadExploitations() {
            this.equipmentService.getExploitations(this.currentModel).then((data) => {
                this.exploitation_lines = data;
            });
        }

        editExploitation(exploitation = null) {
            this.$mdDialog.show(_.assign(require('cam/dialogs/equipment-exploitation-dialog'), {
                locals: {
                    model: exploitation ? _.cloneDeep(exploitation) : {
                        equipment: {id: this.model.id}
                    },
                }
            })).then(this.loadExploitations.bind(this));
        }

        removeExploitation(pk) {
            this.equipmentService.deleteExploitation(pk).then((data) => {
                this.loadExploitations();
            });
        }

        onPaginateExploitations(page, limit) {
            this.ExploitationQuery = _.assign(this.ExploitationQuery, {page: page, limit: limit});
            if (this.exploitation_lines.length < ((this.ExploitationQuery.page - 1) * this.ExploitationQuery.limit)) {
                this.ExploitationQuery.page = 1;
            }
        }

        setEquipmentOwner(line) {
            if (!this.model.equipment_owner) {
                this.model.equipment_owner = line.equipment_owner;
                if (!this.prevent_save) {
                    this.prevent_save = true;
                    this.equipmentService.updateEquipmentOwner(this.model.id, this.model.equipment_owner.id).then((data) => {
                        this.prevent_save = false;
                    }, (err) => {
                        this.prevent_save = false;
                        this.model.equipment_owner = null;
                    });
                }
            } else {
                this.model.equipment_owner = null;
            }
        }

        prePrint() {
            return this.equipmentService.saveEquipment(this.model, this.file);
        }
    }

    module.exports = EquipmentFormCtrl;

})()