/**
 * Created by Amine on 16/08/2016.
 */
(function () {

    'use strict';

    module.exports = CertificateListCtrl;

    CertificateListCtrl.$inject = ["certificateService", "$stateParams", "system"];

    function CertificateListCtrl(service, $stateParams, system) {
        var vm = this;
        var dateFormat = system['date_format'].js;
        var currentPatient = $stateParams.pId;

        vm.$onInit = init;

        function init() {
            vm.certificates = [];

            service.getMedicalCertificates(currentPatient)
                .then(function (result) {
                    vm.certificates = sortMedicalCertificate(result);
                });

            service.itemDeleted.subscribe(itemDeleted);

            function itemDeleted(pk) {
                _.remove(vm.certificates, {id: pk});
            }
        }

        function sortMedicalCertificate(list) {
            return _.sortBy(list, function (o) {
                return -1 * moment(o.certificate_date, dateFormat).valueOf();
            });
        }

    }

})();
