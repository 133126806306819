(function () {
    'use strict';

    class WsConfig {
        constructor(mnWebSocketProvider) {
            // @if DEBUG
            mnWebSocketProvider.debug();
            mnWebSocketProvider.expose();
            // @endif

            mnWebSocketProvider.config();
            mnWebSocketProvider.connect();
        }

        static create() {
            return new WsConfig(...arguments);
        }
    }

    WsConfig.create.$inject = ['mnWebSocketProvider'];

    module.exports = WsConfig.create;

})();
