/**
 * Created by Hp on 04/05/2017.
 */
(function () {
    'use strict';

    var DentalVisitSearchDialog = {
        controller: DentalVisitSearchDialogCtrl,
        controllerAs: "vm",
        template: require("care-sheet/views/dental-consultation-search-modal.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true
    };

    DentalVisitSearchDialogCtrl.$inject = ["$mdDialog", "billingService", "$translate", "system"];

    function DentalVisitSearchDialogCtrl($mdDialog, billingService, $translate, system) {
        let vm = this;
        const dateFormat = system['date_format'].js;

        vm.cancel = $mdDialog.cancel;

        vm.selected = [];
        vm.query = {
            limit: 10,
            page: 1,
            order: "-visit_date",
            hide_paid: false,
            hide_no_amount: true,
            patientFilter: {}
        }

        vm.paginationLabel = {
            page: $translate['instant']('page'),
            rowsPerPage: $translate['instant']('rowsPerPage'),
            of: $translate['instant']('of')
        }

        vm.options = [20, 15, 10, 5];
        vm.selectedConsult = null;
        vm.consults = [];

        vm.$onInit = init;
        vm.addSelection = addSelection;
        vm.onConsultPaginate = onConsultPaginate;
        vm.showProcedures = showProcedures;
        vm.onConsultReorder = onConsultReorder;
        vm.getConsults = getConsults;


        function init() {
            getConsultData();
        }

        function getConsults() {
            const order = vm.query.order[0] == '-' ? 'desc' : 'asc';
            const min = (vm.query.page - 1) * vm.query.limit, max = (vm.query.page) * vm.query.limit;

            return _.chain(vm.consults)
                .orderBy(consult => moment(consult.visit_date, dateFormat).valueOf(), order)
                .slice(min, max)
                .value();
        }

        function getConsultData() {
            if (vm.beneficiary_type === 'PATIENT') {
                vm.query = _.assign(vm.query, {patient: vm.beneficiary.id, only_patient: true})
                vm.consultPromise = billingService
                    .getDentalVisits(vm.query)
                    .then(done);
            }
            if (vm.beneficiary_type === 'ORGANIZATION') {
                vm.query = _.assign(vm.query, {
                    organization: vm.beneficiary.id,
                    only_organization: true
                })
                vm.consultPromise = billingService.getDentalVisits(vm.query)
                    .then(done);
            }

            function done(data) {
                vm.selectedConsult = null;
                vm.consultTotal = data.length;
                vm.consults = data.list;
                if (vm.consultTotal < ((vm.query.page - 1) * vm.query.limit)) {
                    vm.query.page = 1;
                }
            }
        }

        function onConsultPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
        }

        function addSelection() {
            $mdDialog.hide(vm.selected);
        }

        function showProcedures(consult, $event) {
            $event.stopPropagation();

            if (vm.selectedConsult == consult.id) vm.selectedConsult = -1;
            else vm.selectedConsult = consult.id;
        }

        function onConsultReorder(order) {
            vm.query = _.assign(vm.query, {order: order});
        }

    }

    module.exports = DentalVisitSearchDialog;

})();