(function () {

    'use strict';

    class AuthVerificationDialogCtrl {
        constructor($mdDialog, $mdToast, $http, $translate) {
            this.http = $http;
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.translate = $translate;

            this.password = "";
        }

        static get $inject() {
            return ["$mdDialog", "$mdToast", "$http", "$translate"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.http.post("/api/user/verify/", {password: this.password})
                .then(result => {
                    const simpleToast = this.toast.simple()
                        .textContent(this.translate.instant(!!result.data ? "account_password_valid" : "account_password_invalid"))
                        .position("bottom left")
                        .hideDelay(2000);

                    this.dialog.hide(!!result.data);
                    this.toast.show(simpleToast);
                })
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: AuthVerificationDialogCtrl,
        template: require("../views/auth-verification-dialog.tpl.html"),
    };

})();
