(function () {

    'use strict';


    class ContractPointingHistoricDialogCtrl {
        constructor($mdDialog, $translate, system, $http, mnWebSocket, moment) {
            this.system = system;
            this.$mdDialog = $mdDialog;
            this.$translate = $translate;
            this.http = $http;
            this.mnWebSocket = mnWebSocket;
            this.moment = moment;
            this.cancel = $mdDialog.cancel;
            this.onPaginate = this.onPaginate.bind(this);
            this.timeFormat = system['time_format'].js
            this.datetimeFormat = system['datetime_format'].js

        }

        static get $inject() {
            return ["$mdDialog", "$translate", "system", "$http", "mnWebSocket", "moment"];
        }

        $onInit() {
            this.options = [5, 10, 20, 100];
            this.total = 0;
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
            this.filter = {is_deleted: {$ne: true}};
            this.list = [];
            this.query = {
                page: 1,
                limit: 15,
                order: '-id',
                search: {...{is_deleted: {$ne: true}}, ...{beneficiary: this.beneficiary, contract_guest: this.guest}},
                search_all: ''
            }
            this.getData();
        }

        getData() {
            this.promise = this.mnWebSocket.call("billing.ContractPointing.handle_historic", this.query).then(data => {
                this.list = data.list
                this.total = data.length;
                if (this.total < ((this.query.page - 1) * this.query.limit)) {
                    this.query.page = 1;
                }
            });
        }

        onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
            this.getData();
        }

        getTime(t) {
            return t ? this.moment(t, this.datetimeFormat).utc().utcOffset("+0100").format(this.timeFormat) : '';
        }

        getGuests(e) {
            return _.sumBy(e.guests, 'guest_count');
        }

    }

    module.exports = {
        template: require("../views/contract-pointing-historic-dialog.html"),
        controllerAs: "vm",
        controller: ContractPointingHistoricDialogCtrl,
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true
    }

})();