/**
 * Created by Amine on 04/09/2016.
 */
(function () {
    'use strict';

    class SanitizeConfig {
        constructor($provide) {
            $provide.decorator("$sanitize", ($delegate) => {
                return (text, target) => $delegate(text, target);
            });
        }

        static create() {
            return new SanitizeConfig(...arguments);
        }
    }

    SanitizeConfig.create.$inject = ['$provide'];

    module.exports = SanitizeConfig.create;
})();