/**
 * Created by BETALOS on 04/04/2017.
 */
(function () {

    'use strict';

    class Input {
        constructor() {
            this.priority = 0;
            this.restrict = "E"
        }

        static create() {
            return new Input(...arguments);
        }

        compile(element) {
            element.attr('autocomplete', 'off');
        }
    }

    Input.create.$inject = [];

    module.exports = Input.create;

})();