(function () {
    'use strict';

    class InterventionCtrl {
        constructor($state, $mdToast, $translate, printService, interventionService, mnWebSocket) {
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.interventionService = interventionService;
            this.editItem = this.editItem.bind(this);
            this.deleteItem = this.deleteItem.bind(this);
            this.mnWebSocket = mnWebSocket;
            this.mnWebSocket.sub("cam.Intervention.new_notify", (data) => this.reset);
        }

        static get $inject() {
            return ["$state", "$mdToast", "$translate", "printService", "interventionService", "mnWebSocket"];
        }

        $onInit() {
            this.actions = {
                single: [{
                    icon: 'mdi-pencil',
                    label: 'plan_intervention',
                    resource: 'intervention',
                    action: 'update',
                    behavior: 'remove',
                    method: this.editItem
                }, {
                    icon: 'mdi-delete',
                    label: 'delete',
                    resource: 'intervention',
                    action: 'delete',
                    behavior: 'remove',
                    method: this.deleteItem
                }],
                multiple: []
            }
            this.toggle = false;
            this.filter = {is_deleted: {$ne: true}};
            this.tabs = [
                {label: "all", filter: {is_deleted: {$ne: true}}},
                {
                    label: "in_progress_interventions",
                    filter: {is_deleted: {$ne: true}, started: {$eq: true}, finished: {$ne: true}}
                },
                {label: "finished", filter: {is_deleted: {$ne: true}, finished: {$eq: true}, closed: {$eq: false}}},
                {label: "closed", filter: {is_deleted: {$ne: true}, closed: {$eq: true}}},
                {label: "assigned", filter: {is_deleted: {$ne: true}, assigned: {$eq: true}, started: {$ne: true}}},
                {label: "not_assigned", filter: {is_deleted: {$ne: true}, assigned: {$ne: true}}},
            ];

        }

        editItem(e) {
            this.$state.go('app.cam.intervention-form', {modelId: e.id}, {replace: true});
        }

        deleteItem(e) {
            this.promise = this.interventionService.deleteIntervention(e.id).then(this.reset, (err) => {
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant(this.$translate.instant('impossible_delete')))
                    .position("bottom left")
                    .hideDelay(1500));
            });
        }

        show(query) {
            this.filter = _.cloneDeep(query);
            this.reset();
        }
    }

    module.exports = InterventionCtrl;

})()