/**
 * Created by Amine on 16/08/2016.
 */
(function () {
    'use strict';

    module.exports = CertificateFormCtrl;

    CertificateFormCtrl.$inject = ["system", "certificateService", "$stateParams", "$q", "editorManagerService"];

    function CertificateFormCtrl(system, certificateService, $stateParams, $q, editorManagerService) {
        let vm = this;
        let dateFormat = system['date_format'].js;

        vm.editorConfigKey = "certificate";
        vm.editorOpts = {};

        vm.$onInit = init;
        vm.saveCertificate = saveCertificate;
        vm.deleteItem = deleteItem;
        vm.prePrint = prePrint;

        function init() {
            let patientId = vm.patientId = $stateParams.pId;
            let visitId = $stateParams.visitId;

            let certificateId = $stateParams.certificateId;
            let model = $stateParams.model;
            let copy = $stateParams.copy;
            let use = $stateParams.use;

            vm.certificate = {
                content: "",
                certificate_date: moment().format(dateFormat),
                is_model: false,
            };

            vm.editorName = _.uniqueId("certificate-");

            certificateService.getDefaultCategory().then(success);

            if (_.isNil(certificateId)) {
                if (_.isNil(copy) && _.isNil(use)) {
                    if (model) {
                        vm.editorOpts.patient = false;
                        vm.certificate.is_model = true;
                    } else createEmptyMedicalCertificate(patientId, visitId)
                } else {
                    if (_.isNil(copy)) useModel(use, patientId, visitId);
                    else duplicateCertificate(copy, patientId, model);
                }
            } else {
                if (model) editModel(certificateId);
                else loadMedicalCertificate(certificateId, patientId);
            }

            function success(data) {
                if (_.isNil(vm.certificate.category)) {
                    vm.certificate.category = data;
                }
            }
        }

        function createEmptyMedicalCertificate(patientId, visitId) {
            vm.editorOpts.patient = patientId;

            vm.certificate.patient = {id: patientId};
            if (!_.isUndefined(visitId)) {
                vm.certificate.visit = {id: visitId};
            }
        }

        function loadMedicalCertificate(id, patientId) {
            vm.editorOpts.patient = patientId;

            certificateService.getMedicalCertificate(id)
                .then(success, error);

            function success(certificate) {
                vm.certificate = certificate;

                certificateService.itemDeleted.subscribe(function (pk) {
                    if (pk === certificate.id) certificateService.listState();
                });
                certificateService.itemEdited.next(certificate.id);
            }

            function error() {
                certificateService.listState();
            }
        }

        function duplicateCertificate(id, patientId, model) {
            certificateService.itemDuplicated.next(id);
            vm.editorOpts.patient = patientId;

            if (model) certificateService.getModelCertificate(id)
                .then(success);
            else certificateService.getMedicalCertificate(id)
                .then(success);


            function success(certificate) {
                certificate.id = null;
                certificate.title = null; //err -+ rdwn
                if (!model) certificate.certificate_date = moment().format(dateFormat);
                vm.certificate = certificate;
            }
        }

        function useModel(id, patientId, visitId) {
            vm.editorOpts.patient = patientId;

            certificateService.getModelCertificate(id).then(success);

            function success(certificate) {
                vm.certificate = _.assign(vm.certificate, _.omit(certificate, 'id'), {
                    is_model: false,
                    title: "",
                    patient: {id: patientId}
                });

                if (!_.isUndefined(visitId)) {
                    vm.certificate.visit = {id: visitId};
                }
            }
        }

        function editModel(id) {
            certificateService['getModelCertificate'](id).then(success);

            function success(certificate) {
                vm.certificate = certificate;
            }
        }

        function saveCertificate() {
            if (vm.certificate.is_model) saveModelCertificate();
            else saveMedicalCertificate();
        }

        function saveModelCertificate() {
            certificateService
                .saveModelCertificate(vm.certificate, vm.isNew)
                .then(function (data) {
                    vm.certificate = data;
                    certificateService.modelAdded.next(data);
                    certificateService.editState(data, false);
                });
        }

        function saveMedicalCertificate() {
            const deferred = $q.defer();

            editorManagerService
                .getTinymce(vm.editorName)
                .compileContent()
                .then(() => {
                    certificateService
                        .saveMedicalCertificate(vm.certificate, vm.isNew)
                        .then((data) => {
                            vm.certificate = data;
                            certificateService.medicalAdded.next(data);
                            certificateService.editState(data, false);

                            deferred.resolve(data);
                        })
                }, () => deferred.reject(null));

            return deferred.promise;
        }

        function deleteItem() {
            certificateService.deleteItem(vm.certificate)
                .then(certificateService.listState);
        }

        function prePrint() {
            let deferred = $q.defer();

            saveMedicalCertificate()
                .then(function () {
                    deferred.resolve(vm.certificate.id);
                });

            return deferred.promise;
        }
    }

})();
