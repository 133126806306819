
(function () {
    'use strict';

    class PecCtrl {
        constructor($state, billingService, $mdToast, $translate, printService, configService, sequenceService) {
            this.$state = $state;
            this.billingService = billingService;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.configService = configService;
            this.sequenceService = sequenceService;
        }

        static get $inject() {
            return ["$state", "billingService", "$mdToast", "$translate", "printService", "configService", "sequenceService"];
        }

        $onInit() {
            this.filter = {is_deleted: {$ne: true}};
            this.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edition',
                        resource: 'pec',
                        action: 'create',
                        behavior: 'disable',
                        method: this.goToEdition.bind(this)
                    },
                    {
                        icon: 'mdi-printer',
                        label: 'print',
                        resource: 'pec',
                        action: 'create',
                        behavior: 'disable',
                        method: this.handlePrint.bind(this)
                    }],
                multiple: []
            }
        }

        goToEdition(obj) {
            this.$state.go('app.billing.pec-form', {pecId: obj.id});
        }

        showWarning(txt) {
            let simpleToast = this.$mdToast.simple()
                .textContent(this.$translate.instant(txt))
                .position("top right")
                .hideDelay(3000);
            this.$mdToast.show(simpleToast);
        }

        handlePrint(obj) {
            this.printService.printItem('pec', obj.id, null, null);
        }

        addClick(type) {
            this.$state.go("app.billing.pec", {type: type, pecId: null});
        }
    }

    module.exports = PecCtrl;

})()