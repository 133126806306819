(function () {
    'use strict';

    class InterventionFormCtrl {
        constructor($state, $mdToast, $translate, printService, $stateParams, $mdDialog, interventionService, system) {
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.interventionService = interventionService;
            this.$mdToast = $mdToast;
            this.$stateParams = $stateParams;
            this.$mdDialog = $mdDialog;
            this.system = system;
            this.dateFormat = this.system['date_format'].js

            this.currentModel = this.$stateParams['modelId'];

        }

        static get $inject() {
            return ["$state", "$mdToast", "$translate", "printService", "$stateParams", "$mdDialog", "interventionService", "system"];
        }

        $onInit() {
            if (_.isNil(this.currentModel)) {
                this.promise = null;
                this.model = {
                    date: moment().format(this.dateFormat),
                    planning: {steps: []},
                    resolution: {steps: []}
                };
            } else {
                this.promise = this.interventionService.getIntervention(this.currentModel).then((data) => {
                    this.model = data;
                });
            }
        }

        submit(with_cancel = false) {
            if (!this.prevent_save) {
                this.prevent_save = true;
                this.interventionService.saveIntervention(this.model).then((data) => {
                    this.save_success(data, with_cancel);
                }, (err) => {
                    this.prevent_save = false
                });
            }
        }

        save_success(data, with_cancel) {
            this.prevent_save = false;
            if (with_cancel) this.$state.go('app.cam.intervention-form');
            else {
                this.model = data;
                this.$state.go('app.cam.intervention-form', {modelId: data.id}, {replace: true});
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant(
                        _.isNil(this.currentModel) ? this.$translate.instant('save_success') : this.$translate.instant('edit_success')))
                    .position("bottom left")
                    .hideDelay(1500));
            }
        }

        delete() {
            this.promise = this.interventionService.deleteIntervention(this.currentModel).then((data) => {
                this.$state.go('app.cam.intervention');
            }, (err) => {
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant('impossible_delete'))
                    .position("bottom left")
                    .hideDelay(1500));
            });
        }

        handleUpdate(updateData) {
            if (!this.prevent_save) {
                this.prevent_save = true;
                this.interventionService.UpdateIntervention(updateData, this.model.id).then((data) => {
                    this.model = data;
                    this.$mdToast.show(this.$mdToast.simple()
                        .textContent(this.$translate.instant('update_success'))
                        .position("bottom left")
                        .hideDelay(1500));
                    this.prevent_save = false
                }, (err) => {
                    this.prevent_save = false
                });
            }
        }

        handleAssign() {
            if (!this.prevent_save) {
                this.prevent_save = true;
                this.model.resolution.steps = _.cloneDeep(this.model.planning.steps);
                this.interventionService.saveIntervention(this.model).then((data) => {
                    this.model = data;
                    this.prevent_save = false;
                    this.handleUpdate({assigned: true});
                }, (err) => {
                    this.prevent_save = false;
                });
            }
        }

        handleRevoke() {
            this.handleUpdate({assigned: false});
        }

        handleStart() {
            if (!this.prevent_save) {
                this.interventionService.startIntervention(this.model.id).then(data => {
                    this.model = data;
                    this.prevent_save = false;
                }, (err) => {
                    this.prevent_save = false;
                });
            }
        }

        handleFinish() {
            if (!this.prevent_save) {
                this.prevent_save = true;
                this.interventionService.saveIntervention(this.model).then((data) => {
                    this.prevent_save = false;
                    this.interventionService.finishIntervention(this.model.id).then(data => {
                        this.model = data;
                    });
                }, (err) => {
                    this.prevent_save = false;
                });
            }
        }

        handleClose() {
            this.handleUpdate({closed: true});
        }
    }

    module.exports = InterventionFormCtrl;

})()