/**
 * Created by amine on 24/08/2016.
 */

(function () {

    'use strict';

    class ConsultationBodyCtrl {
        constructor($scope, $element, formsService) {
            this.scope = $scope;
            this.element = $element;
            this.formsService = formsService;

            this.formTemplate = null;
            this.values = [];
        }

        static get $inject() {
            return ["$scope", '$element', 'formsService'];
        }

        // get fields() {
        //     const newValues = _.map(this.block.fields, "value");
        //     if (this.values.length === 0 || !!_.difference(this.values, newValues).length) {
        //         this.values = newValues;
        //     }
        //
        //     return this.values;
        // }

        $onInit() {
            this.element
                .addClass('layout-column')
                .addClass('flex-noshrink');

            // const config = {
            //     form: 26,
            //     block: "ecg_1"
            // }

            // this.formsService
            //     .getFormsTemplates(config.form)
            //     .then(data => {
            //         this.formTemplate = data;
            //         this.block = _.find(this.formTemplate.blocks, ["name", config.block]);
            //
            //         // this.scope.$watch(
            //         //     // _.throttle(() => {
            //         //     //     return _.map(this.block.fields, "value");
            //         //     // }, 1000, {trailing: true}),
            //         //     "vm.fields",
            //         //     (ov, nv) => {
            //         //         console.log(nv, _.cloneDeep(this.block.fields));
            //         //     }
            //         // );
            //     });
        }
    }

    module.exports = {
        bindings: {
            titles: '=',
            config: '<',
            consultation: '=ngModel',
            handleAutoSave: "&?autoSave",
            postSaveSubject: "<?",
            readonly: '<?',
            patient: "<"
        },
        controllerAs: "vm",
        controller: ConsultationBodyCtrl,
        template: require('./consultation-body.tpl.html')
    };

})();