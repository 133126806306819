(function () {
        'use strict';

        class camConfigService {
            constructor($q, $http, mnWebSocket) {
                this.q = $q;
                this.http = $http;
                this.ws = mnWebSocket;
            }

            static get $inject() {
                return ["$q", "$http", "mnWebSocket"];
            }

            saveItem(data, base_url) {
                let deferred = this.q.defer();
                let url = `${base_url}${!_.isNil(data.id) ? data.id + "/" : ""}`;
                this.http.post(url, data).then(successCallback, errorCallback);

                function successCallback(response) {

                    deferred.resolve(response.data);
                }

                function errorCallback(err) {
                    deferred.reject(err);
                }

                return deferred.promise;
            }

            deleteItem(pk, base_url) {
                let deferred = this.q.defer();
                let url = `${base_url}${pk}/`;

                this.http.delete(url)
                    .then(success.bind(this), deferred.reject);

                function success(response) {
                    deferred.resolve(response.data);
                }

                return deferred.promise
            }

            checkBeforeDeleteItem(model, pk) {
                return this.ws.call(`${model}.check_dependencies`, {pk});
            }

            handle_activation(pk, activate) {
                return this.ws.call("cam.InterventionGenerator.handle_activation", {pk, activate})
            }

            checkExistence(model, query, pk) {
                return this.ws.call(`${model}.check_existence`, {query, pk})
            }
        }

        module.exports = camConfigService;
    }
)
();
