(function () {

    'use strict';

    // change require debounce

    class NumberPercentage {
        constructor() {
            this.priority = 1;
            this.restrict = "A";

            this.require = "ngModel";
            this.scope = {
                total: "<",
                ngModel: "=",
                discount: "=",
                maxDiscount: "<",
            }
        }

        static create() {
            return new NumberPercentage(...arguments);
        }

        compile(element, attrs) {
            return this.link;
        }

        link(scope, element, attrs, ngModelCtrl) {
            const changeDebounce = _.debounce(() => this.calculatePercentage(scope), 15);
            const elementChangeDebounce = _.debounce(() => this.change(scope, ngModelCtrl), 15);

            const totalWatch = scope.$watch('total', changeDebounce);
            const discountWatch = scope.$watch('discount', changeDebounce);
            const maxDiscountWatch = scope.$watch('maxDiscount', () => this.maxPercentage(scope, element));

            ngModelCtrl.$viewChangeListeners.push(elementChangeDebounce);

            scope.$on('$destroy', () => {
                totalWatch && totalWatch();
                discountWatch && discountWatch();
                maxDiscountWatch && maxDiscountWatch();
            });
        }

        change(scope, ctrl) {
            if (!_.isNil(scope.total) && !_.isNil(ctrl.$modelValue)) scope.$applyAsync(() => {
                const discount = _.round(scope.total * ctrl.$modelValue, 6);
                scope.discount = Math.min(discount, scope.maxDiscount);
            });
        }

        calculatePercentage(scope) {
            scope.$applyAsync(() => {
                const percentage = _.round((scope.discount || 0) / (scope.total || 1), 6);
                scope.ngModel = Math.min(percentage, this.getMaxPercentage(scope));
            });
        }

        maxPercentage(scope, element) {
            element.attr('max', this.getMaxPercentage(scope));
        }

        getMaxPercentage(scope) {
            if (_.isNil(scope.maxDiscount) || _.isNaN(scope.maxDiscount)) return 100;
            else {
                const percentage = _.round((scope.maxDiscount || 0) / (scope.total || 1), 6) * 100;
                return Math.max(percentage, 0);
            }
        }
    }

    NumberPercentage.create.$inject = [];

    module.exports = NumberPercentage.create;

})();
