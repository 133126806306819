/**
 * Created by BETALOS on 08/07/2017.
 */
(function () {

    'use strict';

    module.exports = passwordConfirm;

    passwordConfirm.$inject = [];

    function passwordConfirm() {


        function link(scope, element, attrs, ctrl) {
            scope.$watch(() => scope.$eval(attrs.passwordConfirm), _.mnDelay(newValue => {
                scope.$applyAsync(() => modelCheck(newValue, ctrl));
            }, 500));

            ctrl.$viewChangeListeners.push(() => {
                // check(scope.$eval(attrs.passwordConfirm), ctrl)
            });

            ctrl.$parsers.unshift(viewValue => viewCheck(viewValue, scope.$eval(attrs.passwordConfirm), ctrl));
        }

        function modelCheck(newValue, ctrl) {
            if (ctrl) {
                if (newValue) {
                    if (newValue === ctrl.$viewValue) {
                        ctrl.$setValidity('passwordConfirm', true);
                    } else {
                        ctrl.$setValidity('passwordConfirm', false);
                    }
                } else {
                    ctrl.$setValidity('passwordConfirm', true);
                }
            }
        }

        function viewCheck(viewValue, attrValue, ctrl) {
            if (viewValue) {
                if (viewValue === attrValue) {
                    ctrl.$setValidity('passwordConfirm', true);
                } else {
                    ctrl.$setValidity('passwordConfirm', false);
                }
            } else {
                ctrl.$setValidity('passwordConfirm', true);
            }
            return viewValue;
        }

        return {
            restrict: 'A',
            priority: 0,
            require: 'ngModel',
            link: link,
        };
    }

})()