(function () {
    'use strict';

    class PecFormCtrl {
        constructor($state, billingService, $mdToast, $translate, printService, $stateParams, $mdDialog, system, organizationService, $q) {
            this.$state = $state;
            this.billingService = billingService;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.organizationService = organizationService;
            this.$q = $q;
            this.system = system;
            this.$stateParams = $stateParams;
            this.$mdDialog = $mdDialog;
            this.dateFormat = system['date_format'].js;

            this.pecId = this.$stateParams['pecId'];

            this.SelectProcedure = this.SelectProcedure.bind(this);
            this.patientCallback = this.patientCallback.bind(this);
        }

        static get $inject() {
            return ["$state", "billingService", "$mdToast", "$translate", "printService", "$stateParams", "$mdDialog", "system", "organizationService", "$q"];
        }

        $onInit() {
            this.readOnly = true;
            this.organizations = [];
            this.query = {
                limit: 5,
                page: 1,
                order: "-id"
            }
            this.options = [5, 10, 20];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            }
            this.promises = [this.organizationService.getOrganizations()]
            if (!_.isNil(this.pecId)) {
                this.promises.push(this.billingService.getPec(this.pecId))
            } else {
                this.readOnly = false;
                this.pec = {
                    insured_details: {insured_type: 'PATIENT'},
                    date: moment().format(this.dateFormat),
                    send_date: moment().format(this.dateFormat),
                    details: [],
                };
            }
            this.promise = this.$q.all(this.promises).then(data => {
                this.organizations = data[0];
                if (!_.isNil(this.pecId)) {
                    this.pec = data[1];
                    this.readOnly = this.pec.is_valid;
                }

            })
        }

        removePec() {
            this.billingService.deletePec(this.pec.id).then(data => {
                this.$state.go('app.billing.pec')
            });
        }

        validate(validate = true) {
            this.pec.is_valid = validate;
            this.submit();
        }

        submit(with_cancel) {
            if (!this.prevent_save) {
                this.prevent_save = true;
                this.promise = this.billingService.savePec(this.pec).then(success.bind(this));
            }

            function success(data) {
                this.prevent_save = false;
                if (with_cancel) {
                    this.goBack();
                } else {
                    this.saveSuccess(data);
                }
            }
        }

        goBack() {
            this.$state.go('app.billing.pec');
        }

        saveSuccess(data) {
            this.pec = data;
            this.readOnly = this.pec.is_valid;
            if (_.isNil(this.currentPecId)) this.$state.go('app.billing.pec-form', {pecId: data.id}, {location: 'replace'});
            this.prevent_invalidate = false;
            this.prevent_validate = false;
        }

        handleCommentClick(ev) {
            document.getElementById('comment-input').focus();
        }

        onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
            this.getLines();
        }

        getLines() {
            if (this.pec.details.length < ((this.query.page - 1) * this.query.limit)) {
                this.query.page = 1;
            }
        }

        addEmptyLine() {
            this.pec.details.push({is_comment: true});
        }

        SelectProcedure(data) {
            _.forEach(data, function (procedure) {
                this.pec.details.push({
                    code: procedure.code || procedure.name,
                    description: procedure.name,
                    qte: 1,
                    price: procedure.price,
                    procedure: procedure,
                    discount: 0.0
                });
            }.bind(this));
        }

        getLineSubTotal(item) {
            return item.sub_total || (((item.qte | 0.0) * (item.price || 0.0)) - item.discount);

        }

        getTotalAmount() {
            return _.sumBy(this.pec.details, this.getLineSubTotal)
        }

        removeLine(e, i) {
            this.pec.details.splice(i, 1);
        }

        removeAnswer(e, i) {
            this.pec.answers.splice(i, 1);
        }

        addAnswer(item = null, edit = false, $index = 0) {
            this.$mdDialog.show(_.assign(require('billing/dialogs/pec-answer-dialog'), {
                locals: {
                    answer: edit ? _.cloneDeep(item) : null,
                    query: _.cloneDeep(this.query),
                    options: _.cloneDeep(this.options),
                    paginationLabel: this.paginationLabel,
                    getLineSubTotal: this.getLineSubTotal,
                    details: _.cloneDeep(this.pec.details)
                }
            })).then(data => {
                if (!_.isNil(_.get(data, 'answer'))) {
                    if (edit) {
                        this.pec.answers[$index] = _.get(data, 'answer');
                    } else {
                        this.pec.answers.push(_.get(data, 'answer'));
                    }
                    this.pec.details = _.get(data, 'details');
                }
            });

        }

        patientCallback(data) {
            this.pec.patient = data
            if (this.pec.insured_details.insured_type == 'PATIENT' && !this.pec.is_valid) {
                this.pec.insured_details = _.assign({}, this.pec.insured_details, _.pick(this.pec.patient, 'title', 'first_name', 'last_name', 'birth_date', 'national_id', 'contact_info.address', 'contact_info.city'))
            }
            this.pec.organization = this.pec.organization || _.find(this.organizations,  {id : _.get(this.pec, "patient.default_insurance_id")});
        }


        prePrint() {

        }
    }

    module.exports = PecFormCtrl;

})()