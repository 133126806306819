(function () {
    'use strict';

    class EquipmentCtrl {
        constructor($state, $mdToast, $translate, printService, equipmentService, $mdDialog, system, camConfigService) {
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.equipmentService = equipmentService;
            this.camConfigService = camConfigService;
            this.dialog = $mdDialog;
            this.system = system;
            this.dateFormat = this.system['date_format'].js;
        }

        static get $inject() {
            return ["$state", "$mdToast", "$translate", "printService", "equipmentService", "$mdDialog", "system", "camConfigService"];
        }

        $onInit() {
            this.actions = {
                single: [
                    {
                        icon: 'mdi-pencil',
                        label: 'edit',
                        resource: 'equipment',
                        action: 'update',
                        behavior: 'remove',
                        method: this.editItem.bind(this)
                    },
                    {
                        icon: 'mdi-calendar-clock',
                        label: 'plan_new_intervention',
                        resource: 'intervention',
                        action: 'create',
                        behavior: 'remove',
                        method: this.planIntervention.bind(this)
                    },
                    {
                        icon: 'mdi-printer',
                        label: 'print',
                        resource: 'equipment',
                        action: 'create',
                        behavior: 'remove',
                        method: this.printDoc.bind(this)
                    },
                    {
                        icon: 'mdi-delete',
                        label: 'delete',
                        resource: 'equipment',
                        action: 'delete',
                        behavior: 'remove',
                        method: this.deleteItem.bind(this)
                    }],
                multiple: []
            }
            this.toggle = false;
            this.filter = {is_deleted: {$ne: true}};
        }

        editItem(e) {
            this.$state.go('app.cam.equipment-form', {modelId: e.id}, {replace: true});
        }

        deleteItem(e) {
            this.camConfigService.checkBeforeDeleteItem("cam.Equipment", e.id).then(res => {
                if (!res) {
                    this.promise = this.equipmentService.deleteEquipment(e.id).then(this.reset, this.deleteImpossible.bind(this));
                } else {
                    this.deleteImpossible();
                }
            }, this.deleteImpossible.bind(this));
        }

        deleteImpossible() {
            this.$mdToast.show(this.$mdToast.simple()
                .textContent(this.$translate.instant(this.$translate.instant('impossible_delete')))
                .position("bottom left")
                .hideDelay(1500));
        }

        planIntervention(e) {
            this.dialog.show(_.assign({}, require('cam/dialogs/intervention-planning-dialog'), {
                locals: {
                    model: {
                        equipment: _.cloneDeep(e),
                        date: moment().format(this.dateFormat),
                        planning: {steps: []},
                        resolution: {steps: []}
                    }
                }
            })).then(data => {
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant('save_success'))
                    .position("top right")
                    .hideDelay(1500));
            });

        }

        printDoc(obj) {
            this.printService.printItem('equipment', obj.id, null, null);
        }

    }

    module.exports = EquipmentCtrl;

})()