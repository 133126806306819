/**
 * Created by BETALOS on 10/12/2015.
 */
(function () {
    'use strict';

    class MaterialConfig {
        constructor($mdThemingProvider, $mdIconProvider, mnThemeProvider) {
            $mdThemingProvider.definePalette('mn-light-blue', $mdThemingProvider.extendPalette('light-blue', {'contrastDefaultColor': 'light'}));

            $mdThemingProvider.theme('default')
                .primaryPalette('mn-light-blue', {
                    'default': '500', 'hue-1': '400', 'hue-2': '800',
                })
                .accentPalette('blue-grey', {
                    'default': 'A400' /*, 'hue-1': '500', 'hue-2': '600',*/
                })
                .warnPalette('red', {
                    'default': '700'
                });

            $mdThemingProvider.setDefaultTheme('default');
            mnThemeProvider.setCurrentTheme($mdThemingProvider.theme('default').colors);

            $mdIconProvider.fontSet('mdi', 'mdi');

            $mdIconProvider.icon('html', '/img/svg/html.svg');
            $mdIconProvider.icon('html5', '/img/svg/html5.svg');
            $mdIconProvider.icon('css', '/img/svg/css.svg');
            $mdIconProvider.icon('css3', '/img/svg/css3.svg');
            $mdIconProvider.icon('image', '/img/image.svg');

            // prescription
            $mdIconProvider.icon('prescription-biology', '/img/svg/biology.svg');
            $mdIconProvider.icon('prescription-medicine', '/img/svg/medical.svg');
            $mdIconProvider.icon('prescription-procedure', '/img/svg/procedure.svg');
            $mdIconProvider.icon('prescription-text', '/img/svg/text.svg');
        }

        static create() {
            return new MaterialConfig(...arguments);
        }
    }

    MaterialConfig.create.$inject = ['$mdThemingProvider', '$mdIconProvider', 'mnThemeProvider', '$mdColorPalette'];

    module.exports = MaterialConfig.create;

})();
