/**
 * Created by Hp on 12/07/2017.
 */
(function () {

    'use strict';

    // advance toast
    class AdvanceToastCtrl {
        constructor($mdToast, $timeout) {
            this.toast = $mdToast;
            this.timeout = $timeout;
            this.label = this.label ? this.label : "loading_medicines";
        }

        $onInit() {
            if (this.handle.finally) this.handle.finally(() => this.toast.hide());
            else if (_.isFunction(this.handle)) this.handle().then((response) => this.toast.hide(response), (err) => this.toast.hide(err));
            else this.timeout(() => this.toast.hide(), 200);
        }

        static get $inject() {
            return ["$mdToast", "$timeout"];
        }
    }

    const advancedToast = {
        hideDelay: 0,
        controllerAs: 'vm',
        controller: AdvanceToastCtrl,
        bindToController: true,
        position: 'bottom left',
        template: `
            <md-toast class="mn-progress-toast">
                <md-progress-circular md-diameter="25" md-mode="indeterminate" ></md-progress-circular>
                <div class="layout-column flex">
                    <span class="md-toast-text flex"  ng-bind="vm.label | translate"></span>
                    <span ng-if="vm.description" class="flex mn-toast-subtext" ng-bind="vm.description | translate"></span>
                </div>
            </md-toast>
        `,
    };

    // video call toast
    class VideoCallToastCtrl {
        constructor($mdToast, videoCallService) {
            this.toast = $mdToast;
            this.videoCallService = videoCallService;

            this.call = this.call || {};
        }

        openCall(ev) {
            this.closeToast();
            this.videoCallService.openVideoCall(this.call.room, this.call.patient_id, this.call.folder_id, ev);
        }

        closeToast() {
            this.toast.hide()
        }

        static get $inject() {
            return ["$mdToast", "videoCallService"];
        }
    }

    const videoCallToast = {
        hideDelay: 0,
        controllerAs: 'vm',
        bindToController: true,
        position: 'bottom left',
        controller: VideoCallToastCtrl,
        template: `
            <md-toast class="mn-video-call-toast">
                <span class="md-toast-text" flex translate="video_call_patient_stated_call" translate-values="vm.call"></span>
                <md-button class="md-icon-button md-primary" ng-click="vm.openCall($event)">
                       <md-icon md-font-icon="mdi-video" md-font-set="mdi"></md-icon>
                </md-button>
                <md-button class="md-icon-button md-warn" ng-click="vm.closeToast()">
                       <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                </md-button>
            </md-toast> 
        `,
    };

    // config/codebase update toast
    class UpdateToastCtrl {
        constructor($mdToast) {
            this.toast = $mdToast;
            this.label = this.label ? this.label : "loading_medicines";
        }

        reload() {
            this.closeToast();
            window.location.reload();
        }

        closeToast() {
            this.toast.hide()
        }

        static get $inject() {
            return ["$mdToast"];
        }
    }

    const updateToast = {
        hideDelay: 0,
        controllerAs: 'vm',
        bindToController: true,
        position: 'bottom left',
        controller: UpdateToastCtrl,
        template: `
            <md-toast class="mn-video-call-toast">
                <span class="md-toast-text" flex ng-bind="vm.label | translate"></span>
                <md-button class="md-icon-button md-primary" ng-click="vm.reload()">
                       <md-icon md-font-icon="mdi-refresh" md-font-set="mdi"></md-icon>
                </md-button>
                <md-button class="md-icon-button md-warn" ng-click="vm.closeToast()">
                       <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                </md-button>
            </md-toast> 
        `,
    };

    class ToastConfig {
        constructor($mdToastProvider) {
            $mdToastProvider.addPreset('mnAdvancedToast', {
                methods: ['handle', 'label', 'description'],
                options: () => advancedToast
            });

            $mdToastProvider.addPreset('mnVideoCallToast', {
                methods: ['call'],
                options: () => videoCallToast
            });

            $mdToastProvider.addPreset('mnUpdateToast', {
                methods: ['label'],
                options: () => updateToast
            });
        }

        static create() {
            return new ToastConfig(...arguments);
        }
    }

    ToastConfig.create.$inject = ['$mdToastProvider'];

    module.exports = ToastConfig.create;


})();
