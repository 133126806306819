/**
 * Created by BETALOS on 26/02/2016.
 */
(function () {
    'use strict';

    class Time {
        constructor(moment, system) {
            this.priority = 0;
            this.restrict = "A"
            this.require = "^ngModel";

            this.moment = moment;
            this.timeFormat = system['time_format'].js;
            this.dateTimeFormat = system['date_format'].naive;
        }

        static create() {
            return new Time(...arguments);
        }

        link(scope, element, attrs, ctrl) {
            const dateTime = element.is('[dateTime]');

            ctrl.$formatters = _.castArray((valueFromModel) => this.formatter(valueFromModel));
            ctrl.$parsers = _.castArray((valueFromInput) => this.parser(valueFromInput, dateTime));
        }

        formatter(valueFromModel) {
            let time = null;

            if (_.isString(valueFromModel)) {
                let momentDate = this.moment(valueFromModel, this.moment.ISO_8601);

                if (momentDate.isValid()) time = momentDate.format(this.timeFormat);
                else time = this.moment(valueFromModel, this.timeFormat).format(this.timeFormat);
            }

            else if (this.moment.isMoment(valueFromModel)) time = valueFromModel.format(this.timeFormat);
            else if (_.isDate(valueFromModel)) time = this.moment(valueFromModel).format(this.timeFormat);

            return time;
        }

        parser(valueFromInput, dateTime) {
            if (_.isString(valueFromInput)) {
                const time = this.moment(valueFromInput, this.timeFormat);
                return dateTime ? time.format(this.dateTimeFormat) : time.format(this.timeFormat);
            } else return null;
        }

    }

    Time.create.$inject = ["moment", "system"];

    module.exports = Time.create;

})();