(function () {
    'use strict';
    const {HTMLS} = require('shared/utils/requires');

    class CamConfigItemCtrl {
        constructor($state, $mdToast, camConfigService, $mdDialog, $translate, $stateParams) {
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.camConfigService = camConfigService;
            this.$mdDialog = $mdDialog;
            this.$translate = $translate;
            this.$stateParams = $stateParams;


            this.dialogTpl = this.$stateParams['dialogTpl'];
            this.dialog = this.$stateParams['dialog'];
            this.apiUrl = this.$stateParams['apiUrl'];
            this.resource = this.$stateParams['resource'];
            this.mnModel = this.$stateParams['mnModel'];
            this.columnsPath = this.$stateParams['columnsPath'];

            this.editItem = this.editItem.bind(this);
            this.deleteItem = this.deleteItem.bind(this);
        }

        static get $inject() {
            return ["$state", "$mdToast", "camConfigService", "$mdDialog", "$translate", "$stateParams"];
        }

        $onInit() {
            this.actions = {
                single: [{
                    icon: 'mdi-pencil',
                    label: 'edit',
                    resource: this.resource,
                    action: 'update',
                    behavior: 'remove',
                    method: this.editItem
                }, {
                    icon: 'mdi-delete',
                    label: 'delete',
                    resource: this.resource,
                    action: 'delete',
                    behavior: 'remove',
                    method: this.deleteItem
                }],
                multiple: [{
                    icon: 'mdi-plus',
                    label: 'add',
                    resource: this.resource,
                    action: 'create',
                    behavior: 'remove',
                    method: this.editItem,
                    isStatic: true
                }]
            }
            this.toggle = false;
            this.filter = {is_deleted: {$ne: true}};
        }

        editItem(item, ev) {
            let d = this.dialogTpl ? require('cam/dialogs/config-item-edit-dialog') : require(`cam/dialogs/${this.dialog}`);
            let tpl = this.dialogTpl ? {template: HTMLS[this.dialogTpl]} : {};
            const dialogConfig = _.assign({}, d, tpl, {
                targetEvent: ev,
                locals: {
                    url: this.apiUrl,
                    model: _.cloneDeep(item),
                    mnModel: this.mnModel
                },
            });
            this.$mdDialog.show(dialogConfig).then(this.reset, this.reset);
        }

        deleteItem(item, ev) {
            if (this.resource == 'intervention-generator') {
                if (item.active) this.deleteImpossible();
                else this.promise = this.camConfigService.deleteItem(item.id, this.apiUrl).then(this.reset, this.deleteImpossible.bind(this));
            } else {
                this.camConfigService.checkBeforeDeleteItem(this.mnModel, item.id).then(res => {
                    if (!res) {
                        this.promise = this.camConfigService.deleteItem(item.id, this.apiUrl).then(this.reset, this.deleteImpossible.bind(this));
                    } else {
                        this.deleteImpossible();
                    }
                }, this.deleteImpossible.bind(this));
            }
        }

        deleteImpossible() {
            this.$mdToast.show(this.$mdToast.simple()
                .textContent(this.$translate.instant(this.$translate.instant('impossible_delete')))
                .position("bottom left")
                .hideDelay(1500));
        }
    }

    module.exports = CamConfigItemCtrl;

})()