/**
 * Created by BETALOS on 03/05/2017.
 */
(function () {

    'use strict';

    class UniquenessDirective {
        constructor(camConfigService) {
            this.priority = 0;
            this.restrict = "A";
            this.require = "ngModel";
            this.scope = {
                mnQuery: "=mnUniqueness", ngModel: "="
            };
            this.camConfigService = camConfigService;
        }

        static create() {
            return new UniquenessDirective(...arguments);
        }

        link(scope, element, attrs, ngModelController) {
            scope.$watch('ngModel', (value, oldValue) => this.modelChanged(value, oldValue, scope, ngModelController));
        }

        modelChanged(value, oldValue, scope, ctrl) {
            if (value === oldValue) return false;
            else {
                this.camConfigService.checkExistence(scope.mnQuery.mnModel, scope.mnQuery.query, scope.mnQuery.model).then(data => {
                    ctrl.$setValidity('unique', data);
                });
            }
        }
    }

    UniquenessDirective.create.$inject = ["camConfigService"];

    module.exports = UniquenessDirective.create;

})();
