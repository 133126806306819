/**
 * Created by Amine on 26/07/2016.
 */
(function () {
    'use strict';

    class Number {
        constructor($mdConstant) {
            this.priority = 0;
            this.restrict = "A";
            this.require = 'ngModel';

            this.up = $mdConstant.KEY_CODE.UP_ARROW;
            this.down = $mdConstant.KEY_CODE.DOWN_ARROW;
        }

        static create() {
            return new Number(...arguments);
        }

        link(scope, element, attrs, ngModelCtrl) {
            const type = attrs['mnType'];
            const percentage = type === 'percentage';

            const toFix = attrs['mnNumber'];
            const noKeyEvent = element.is('[no-key-event]');
            const selectContent = element.is('[select-content]');
            const step = attrs['mnStep'] ? parseFloat(attrs['mnStep']) : percentage ? 5 : 1;

            const allowZero = this.allowedZero(scope, attrs);
            const allowEmpty = this.allowedEmpty(scope, attrs, percentage);

            const config = {toFix, step, allowZero, allowEmpty};

            if (percentage) {
                element.attr('min', 0);
                element.attr('max', 100);
            }

            element.on('drop', ev => this.dropHandler(ev));
            element.on('dragover', ev => this.dropHandler(ev));
            element.on('dragenter', ev => this.dropHandler(ev));
            element.on("change", () => this.change(element, ngModelCtrl, config));

            element.on('mousewheel', ev => this.handleWheel(element, config, ngModelCtrl, ev));
            if (!noKeyEvent) element.on('keydown', ev => this.handleKeypress(element, config, ngModelCtrl, ev));

            if (selectContent) element.on('focus', ev => $(ev.currentTarget).select());

            if (element.is('[blur-update]')) ngModelCtrl.$overrideModelOptions({
                updateOn: 'blur'
            });

            ngModelCtrl.$parsers = _.castArray(
                value => percentage ? this.parserPercentage(value, config) : this.parser(value, element, config)
            );

            ngModelCtrl.$formatters = _.castArray(
                value => percentage ? this.formatterPercentage(value, config, element) : this.formatter(value, element, config)
            );
        }

        allowedZero(scope, attrs) {
            const value = scope.$eval(attrs['mnAllowZero']);
            return _.isNil(value) ? true : value;
        }

        allowedEmpty(scope, attrs, percentage) {
            const value = scope.$eval(attrs['mnEmpty']);
            return _.isNil(value) ? (!percentage) : value;
        }

        dropHandler(event) {
            event.preventDefault();
        }

        change(element, ctrl, config) {
            const value = parseFloat(element.val());

            const max = this.maxValue(element);
            const min = this.minValue(element, config);

            if (!_.isNaN(max) && value > max) return this.setValueToModel(ctrl, max, config, element);
            else if (!_.isNaN(min) && value < min) return this.setValueToModel(ctrl, min, config, element);
            else this.setValueToModel(ctrl, value, config, element);
        }

        setValueToModel(ctrl, value, config, element) {
            const handledValue = this.handleValue(element, value, config);
            this.setValueToCtrl(handledValue, ctrl);
        }

        setValueToCtrl(value, ctrl) {
            ctrl.$setViewValue(value, 'change');
            ctrl.$commitViewValue();
            ctrl.$render();
        }

        handleValue(element, value, config) {
            if (_.isNumber(value) && !_.isNaN(value)) return value.toFixed(config.toFix);
            if (_.isEmpty(value) && !config.allowEmpty) {
                const min = this.minValue(element, config);
                return min.toFixed(config.toFix);
            }
            if (!_.isEmpty(value) && _.isString(value)) return parseFloat(value).toFixed(config.toFix);
        }

        handleWheel(element, config, ctrl, ev) {
            if (this.blockedElement(element)) return;

            ev.preventDefault();
            let value = null;

            const min = this.minValue(element, config);
            const valueFromView = parseFloat(element.val() || min);

            if (ev.originalEvent.wheelDelta / 120 > 0) value = this.handleIncremental(element, valueFromView, config);
            else value = this.handleDecremental(element, valueFromView, config);

            this.setValueToElement(element, value, config, ctrl);
        }

        handleKeypress(element, config, ctrl, ev) {
            if (!_.includes([this.up, this.down], ev.keyCode) || this.blockedElement(element)) return;

            ev.preventDefault();
            let newValue = null;

            const min = this.minValue(element, config);
            const valueFromView = parseFloat(element.val() || min);

            if (this.up === ev.keyCode) newValue = this.handleIncremental(element, valueFromView, config);
            if (this.down === ev.keyCode) newValue = this.handleDecremental(element, valueFromView, config);

            this.setValueToElement(element, newValue, config, ctrl);
        }

        setValueToElement(element, value, config, ctrl) {
            let handledValue = this.handleValue(element, value, config);

            setTimeout(() => {
                this.setValueToCtrl(handledValue, ctrl);
            });
        }

        handleIncremental(element, value, config) {
            const max = this.maxValue(element);
            const newValue = value + config.step;

            return !_.isNaN(max) ? Math.min(newValue, max) : newValue;
        }

        handleDecremental(element, value, config) {
            const newValue = value - config.step;
            const min = this.minValue(element, config);

            return !_.isNaN(min) ? Math.max(newValue, min) : newValue;
        }

        // ngModelHandlers
        formatter(model, element, config) {
            let empty = _.isEmpty(model) || _.isNil(model) || _.isNaN(model);

            if (!_.isNumber(model)) {
                if (empty && !config.allowEmpty) {
                    const min = this.minValue(element, config);
                    return min.toFixed(config.toFix);
                } else return null;
            } else return model.toFixed(config.toFix);
        }

        parser(view, element, config) {
            let empty = _.isEmpty(view) || _.isNil(view) || _.isNaN(view);

            if (empty && !config.allowEmpty) return this.minValue(element, config);
            else if (!empty) return parseFloat(view);
            else return null;
        }

        formatterPercentage(model, config, element) {
            let newValue = null;

            if (!_.isNumber(model) || _.isNaN(model)) {
                const min = this.minValue(element, config);
                newValue = min.toFixed(config.toFix);
            } else newValue = (model * 100).toFixed(config.toFix);

            if (!_.isNull(newValue)) {
                element.parent().addClass('md-input-has-value');
            }

            return newValue;
        }

        parserPercentage(view, config) {
            if (_.isEmpty(view) && !config.allowEmpty) return 0;
            else {
                return parseFloat(view) / 100;
            }
        }

        maxValue(element) {
            return parseFloat(element.attr('max'));
        }

        minValue(element, config) {
            return parseFloat(element.attr('min') || (config.allowEmpty ? null : 0));
        }

        blockedElement(element) {
            return !element.is(":focus") || element.is('[readonly]') || element.is('[disabled]');
        }
    }

    Number.create.$inject = ["$mdConstant"];

    module.exports = Number.create;

})();

