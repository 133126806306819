/**
 * Created by amine on 08/03/2018.
 */
(function () {

    'use strict';

    const angular = require('angular');

    const careSheetService = require("./services/careSheetService");

    const PrintSheetCtrl = require("./components/print-sheet");

    const CareSheetCtrl = require("./controllers/CareSheetCtrl");
    const CareSheetFormCtrl = require("./controllers/CareSheetFormCtrl");

    angular
        .module("medinet")

        .service('careSheetService', careSheetService)

        .component('mnPrintSheet', PrintSheetCtrl)

        .controller('CareSheetCtrl', CareSheetCtrl)
        .controller('CareSheetFormCtrl', CareSheetFormCtrl);


})();