/**
 * Created by BETALOS on 19/11/2015.
 */
(function () {
    'use strict';

    const angular = require('angular');

    const ngMaterial = require('angular-material');
    const ngMessages = require('angular-messages');
    const uiRouter = require('@uirouter/angularjs')['default'];

    const dataTable = require('angular-material-data-table');
    const ngFileUpload = require('ng-file-upload');
    const angularDragula = require('angular-dragula');

    const ngTranslate = require('angular-translate');
    require('angular-translate-once');

    const angularMoment = require('angular-moment');

    const satellizer = require('satellizer');
    const ngSanitize = require('angular-sanitize');

    require('md-rainbow');

    const cgBusy = require('@cgross/angular-busy');

    const dependencies = [
        ngMaterial,
        uiRouter,
        ngTranslate,
        satellizer,
        ngMessages,
        dataTable,
        ngFileUpload,
        angularDragula(angular),
        ngSanitize,
        'mdRainbow',
        cgBusy,
        angularMoment,
    ];

    angular
        .module('medinet', dependencies);

    require('./core');
    require('shared/');
    require('auth/');
    require('visit/');
    require('patient/');
    require('consultation/');
    require('certificate/');
    require('exam/');
    require('frontdesk/');
    require('payment/');
    require('parameters/');
    require('prescription/');
    require('dental-consultation/');
    require('specifics/');
    require('billing/');
    require('stock/');
    require('stand-alone/');
    require('general-payment/');
    require('care-sheet/');
    require('oximetry/');
    require('rehabilitation/');
    require('generic-states/');
    require('hospitalization/');
    require('workflow/');
    require('medical-care/');
    require('forms/');
    require('cam/');


})();
