/**
 * Created by BETALOS on 08/07/2017.
 */
(function () {

    'use strict';

    class AccountUpdateDialogCtrl {
        constructor($scope, $mdDialog, authService, actorService, $q) {
            this.q = $q;
            this.scope = $scope;
            this.dialog = $mdDialog;
            this.authService = authService;
            this.actorService = actorService;

            this.currentStaff = _.get(authService, 'staff.id', false);
        }

        static get $inject() {
            return ["$scope", "$mdDialog", "authService", "actorService", "$q"];
        }

        $onInit() {
            this.handleUser = !this.currentStaff;
            this.user = _.cloneDeep(this.authService.user);

            if (this.currentStaff) this.promise = this.actorService.getStaff(this.currentStaff)
                .then(data => {
                    this.staff = data;

                    if (!this.staff['contact_info']) {
                        this.staff = _.assign(this.staff, {contact_info: {phone_numbers: []}});
                    }
                });
        }

        cancel() {
            this.dialog.cancel();
        }

        unsetValidity() {
            this.scope.accountForm.password.$setValidity("passwordInvalid", true);
        }

        submit() {
            this.promise = this.promiseGenerator()
                .then(() => {
                    this.cancel();
                    this.authService.logout();
                })
                .catch(() => this.scope.accountForm.password.$setValidity("passwordInvalid", false));
        }

        promiseGenerator() {
            let deferred = this.q.defer();

            if (this.staff && this.handleUser) {
                this.actorService.partialUpdateStaff(this.staff)
                    .then(
                        () => this.authService.handleUserAccount(this.user).then(deferred.resolve, deferred.reject),
                        () => deferred.reject()
                    );

                return deferred.promise;
            }
            else if (this.staff && !this.handleUser) return this.actorService.partialUpdateStaff(this.staff);
            else if (!this.staff && this.handleUser) return this.authService.handleUserAccount(this.user);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: AccountUpdateDialogCtrl,
        template: require("../views/account-update-dialog.tpl.html"),
    };

})();