(function () {

    'use strict';

    class AsyncClick {
        constructor($timeout, $parse, $interpolate) {
            this.restrict = "A";
            this.$parse = $parse;
            this.$timeout = $timeout;
            this.interpolate = $interpolate;
        }

        static create() {
            return new AsyncClick(...arguments);
        }

        link(scope, element, attrs) {
            const fn = this.$parse(attrs['asyncClick']);

            element.on('click', (event) => {
                element.prop('disabled', true);

                scope.$apply(() => {
                    const call = fn(scope, {$event: event});
                    if (this.isPromise(call)) call.finally(() => this.unDisable(element, scope));
                    else this.$timeout(() => this.unDisable(element, scope), 200);
                });
            });
        }

        unDisable(element, scope) {
            if (element.is("[ng-disabled]")) {
                element.prop('disabled', this.interpolate(`{{${element.attr("ng-disabled")}}}`)(scope.$parent));
            } else {
                element.prop('disabled', false);
            }
        }

        isPromise(value) {
            return Boolean(value && typeof value.then === 'function');
        }
    }

    AsyncClick.create.$inject = ['$timeout', '$parse', '$interpolate'];

    module.exports = AsyncClick.create;

})();