/**
 * Created by Amine on 19/10/2016.
 */
(function () {

    'use strict';

    module.exports = CertificateViewCtrl;

    CertificateViewCtrl.$inject = ["certificateService", "$stateParams"];

    function CertificateViewCtrl(service, $stateParams) {
        var vm = this;

        vm.$onInit = init;

        function init() {
            vm.patientId = $stateParams.pId;
            vm.visitId = $stateParams.visitId;
            vm.certificateId = $stateParams.certificateId;

            loadMedicalCertificate();
        }


        function loadMedicalCertificate() {
            service.getMedicalCertificate(vm.certificateId)
                .then(success);

            function success(certificate) {
                service.itemActive.next(certificate.id);
                vm.certificate = certificate;
            }
        }
    }

})();
