/**
 * Created by amine on 28/03/2017.
 */
(function () {
    'use strict';

    const {BehaviorSubject} = require("rxjs");

    class ScrollService {
        constructor() {
            this.subjects = {};
        }

        static get $inject() {
            return [];
        }

        registerContainer(name, subscription, defaultValue) {
            if (_.isNil(defaultValue)) defaultValue = '';
            if (_.isNil(this.subjects[name])) this.subjects[name] = new BehaviorSubject(defaultValue);

            return this.subjects[name].subscribe(subscription);
        }

        sref(container, item) {
            if (!_.isNil(this.subjects[container])) {
                this.subjects[container].next(item);
            }
        }
    }

    module.exports = ScrollService;

})();
