(function () {
    'use strict';

    class BillingCreditNoteCtrl {
        constructor($state, billingService, $mdToast, $translate, printService, $stateParams, $mdDialog, system, $transition$, $q, configService) {
            this.$state = $state;
            this.billingService = billingService;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.$q = $q;
            this.system = system;
            this.$stateParams = $stateParams;
            this.configService = configService;
            this.$mdDialog = $mdDialog;
            this.dateFormat = system['date_format'].js;
            this.currentCreditNote = $transition$.params('to')["creditNoteId"];
            this.patientCallback = this.patientCallback.bind(this);
            this.removePatient = this.removePatient.bind(this);
            this.SelectProcedure = this.SelectProcedure.bind(this);
        }

        static get $inject() {
            return ["$state", "billingService", "$mdToast", "$translate", "printService", "$stateParams", "$mdDialog", "system", "$transition$", "$q", "configService"];
        }

        $onInit() {
            this.readOnly = false;
            this.modelName = "BillingCreditNote";
            this.query = {
                limit: 5,
                page: 1,
                order: "code"
            }
            this.options = [5, 10, 20];
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            }
            this.promises = [this.configService.get(["price_mode"], true)];

            if (!_.isNil(this.currentCreditNote)) {
                this.promises.push(this.billingService.getCreditNote(this.currentCreditNote));
            } else {
                this.creditNote = {doc_date: moment().format(this.dateFormat), lines: [], type: 'DISCOUNT'};
            }
            this.promise = this.$q.all(this.promises).then(data => {
                if (!_.isNil(this.currentCreditNote)) this.creditNote = data[1];
                else {
                    this.creditNote.price_mode = data[0];
                }
            });
        }

        submit(with_cancel) {

            if (!this.preventSave) {
                this.preventSave = true;
                this.promise = this.save().then(success.bind(this), error.bind(this));
            }

            function success(data) {
                this.preventSave = false;
                if (with_cancel) window.history.back();
                else this.saveSuccess(data);
            }

            function error(err) {
                this.preventSave = false;
            }
        }

        save() {
            return this.billingService.saveCreditNote(this.creditNote);
        }

        saveSuccess(data) {
            this.creditNote = data;
            if (_.isNil(this.currentCreditNote)) {
                this.$state.go('app.billing.credit-note-form', {creditNoteId: data.id}, {location: 'replace'});
            }
        }

        cancel() {
            window.history.back();
        }


        prePrint() {
            return this.save();
        }

        patientCallback(data) {
            this.creditNote.patient = data
        }

        removePatient() {
            this.creditNote.patient = null;
        }


        remove() {
            this.billingService.deleteCreditNote(this.creditNote.id).then((data) => {
                this.$state.go('app.billing.credit-note');
            })
        }

        invalidate() {
            this.creditNote.is_valid = false;
            this.submit();
        }

        validate() {
            this.creditNote.is_valid = true;
            this.submit();
        }

        selectInvoice(ev) {
            this.$mdDialog.show(_.assign(require('stock/dialogs/document-search-dialog'), {
                targetEvent: ev,
                locals: {
                    modalIcon: "mdi-receipt",
                    modalTitle: "invoice_list",
                    modalModel: "billing.Invoice",
                    search: {
                        is_deleted: {$ne: true},
                        is_valid: {$ne: false},
                        beneficiary_type: 'PATIENT',
                        beneficiary_name: _.get(this.creditNote.patient, 'full_name')
                    },
                }
            })).then(function (data) {
                _.forEach(data, function (invoice) {
                    _.forEach(invoice.invoice_details, function (detail) {
                        _.forEach(detail.sub_details, function (item) {
                            const line = _.find(this.creditNote.lines, function (line) {
                                return !_.isNil(line.article) && line.code === item.code && line.article.id === item.article.id;
                            });

                            if (!line) {
                                this.creditNote.lines.push(_.assign(item, {
                                    src_doc_number: invoice.number,
                                    src_doc_cls: "Invoice",
                                    src_doc_id: invoice.id
                                }));
                            }
                        }.bind(this));

                    }.bind(this))
                }.bind(this));
            }.bind(this));


        }

        selectArticle(ev) {
            this.$mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(function (data) {
                _.forEach(data, function (article) {
                    let line = _.find(this.creditNote.lines, function (l) {
                        return l.code == article.code;
                    });
                    if (!line) this.creditNote.lines.push({
                        code: article.code,
                        description: article.short_desc,
                        qte: 1,
                        price: article.sale_price,
                        discount: 0.0,
                        article: _.cloneDeep(article)
                    });
                }.bind(this));
            }.bind(this));
        }


        SelectProcedure(data) {
            _.forEach(data, function (procedure) {
                if (!_.find(this.creditNote.lines, {code: procedure.code})) {
                    this.creditNote.lines.push({
                        code: procedure.code || procedure.name,
                        description: procedure.name,
                        qte: 1,
                        price: procedure.price,
                        procedure_uid: procedure.uid,
                        discount: 0.0
                    });
                }
            }.bind(this));
        }

        addEmptyLine(ev) {
            this.creditNote.lines.push({is_comment: true});
        }

        getSubTotal(item) {
            return item.sub_total || (((item.qte | 0.0) * (item.price || 0.0)) - item.discount);
        }

        removeLine(idx) {
            this.creditNote.lines.splice((this.query.page - 1) * this.query.limit + idx, 1);
        }

        getTotal() {
            return this.creditNote ? _.sumBy(this.creditNote.lines, function (item) {
                return this.getSubTotal(item);
            }.bind(this)) : 0;
        }


    }

    module.exports = BillingCreditNoteCtrl;

})()