(function () {

    'use strict';

    class ContractMultiRenewDialogCtrl {
        constructor($mdDialog, $http, mnWebSocket, system) {
            this.http = $http;
            this.mnWebSocket = mnWebSocket;
            this.dialog = $mdDialog;
            this.dateFormat = system['date_format'].js;
        }

        static get $inject() {
            return ["$mdDialog", "$http", "mnWebSocket", "system"];
        }

        $onInit() {
            this.deadline = moment().format(this.dateFormat);
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.dialog.hide(this.deadline);
        }

    }

    module.exports = {
        template: require("../views/contract-multirenew-confirm-dialog.html"),
        controllerAs: "vm",
        controller: ContractMultiRenewDialogCtrl,
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    }

})();