/**
 * Created by BETALOS on 19/11/2015.
 */
(function () {

    'use strict';

    class Acl {
        constructor(authService) {
            this.priority = 0;
            this.restrict = "A";
            this.service = authService;
        }

        static create() {
            return new Acl(...arguments);
        }

        link(scope, element, attrs) {
            let acl = scope.$eval(attrs['mnAcl']);
            let allowEmpty = element.is('[allow-empty]');
            let behavior = _.get(acl, 'behavior', 'remove');
            /*
            * immediate introduced
            * because requestAnimationFrame causes issue with tab
            * need some tests
            */
            let immediate = _.get(acl, 'immediate', false);

            if (allowEmpty && _.isEmpty(acl)) {
            } else if (!this.service.aclHandler(acl)) {
                if (behavior === 'disable' || behavior === 'disabled') attrs.$set('disabled', true);
                else if (behavior === 'read-only' || behavior === 'readonly') attrs.$set('readonly', true);
                else if (behavior === 'hide') element.hide();
                else if (behavior !== 'show') immediate ? element.remove() : requestAnimationFrame(() => element.remove());
            } else if (behavior === 'show') {
                requestAnimationFrame(() => element.remove());
            }

        }
    }

    Acl.create.$inject = ['authService'];

    module.exports = Acl.create;

})();
