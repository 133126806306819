/**
 * Created by amine on 19/10/2016.
 */
(function () {
    'use strict';

    let mnCertificateItem = {
        controller: mnCertificateController,
        controllerAs: "vm",
        bindings: {
            item: '<mnItem'
        },
        template: require('certificate/views/certificate-item.tpl.html')
    };

    mnCertificateController.$inject = ["$translate", "certificateService", "$element", "$timeout", "$sce"];

    function mnCertificateController($translate, certificateService, $element, $timeout, $sce) {
        let vm = this;

        vm.$onInit = init;
        vm.constructedTitle = constructedTitle;
        vm.editItem = editItem;
        vm.duplicateItem = duplicateItem;
        vm.deleteItem = deleteItem;

        function init() {
            vm.item.trustedContent = $sce.trustAsHtml(vm.item.content);

            if ($element.is('[mn-unique')) {
                $timeout(function () {
                    $element
                        .addClass("layout-column")
                        .addClass("flex");

                    $("md-card, md-card > md-card-content", $element).addClass("flex");
                })
                certificateService.itemDeleted.subscribe(itemDeleted);
            }

            function itemDeleted(pk) {
                if (pk == vm.item.id) certificateService.listState();
            }
        }

        function constructedTitle() {
            if (_.isUndefined(vm.item))
                return false;
            if (_.isNull(vm.item.title) || _.isEmpty(vm.item.title))
                return $translate.instant("certificate_of") + " " + vm.item.certificate_date;
            return vm.item.title + " (" + vm.item.certificate_date + ")";
        }

        function editItem() {
            certificateService.editState(vm.item);
        }

        function duplicateItem() {
            certificateService.duplicateState(vm.item);
        }

        function deleteItem() {
            certificateService.deleteItem(vm.item);
            $element.fadeOut();
        }
    }

    module.exports = mnCertificateItem;
})();