(function () {

    'use strict';

    class AuthConfig {
        constructor($authProvider) {
            $authProvider.tokenType = 'JWT';
            $authProvider.loginUrl = '/api/auth/';
            $authProvider.tokenHeader = 'Authorization';
        }

        static create() {
            return new AuthConfig(...arguments);
        }
    }

    AuthConfig.create.$inject = ['$authProvider'];

    module.exports = AuthConfig.create;

})();
