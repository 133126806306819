/**
 * Created by Hp on 12/05/2017.
 */
(function () {

    'use strict';

    class InterventionStepEditDialogCtrl {
        constructor($mdDialog, $http) {
            this.http = $http;
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog", "$http"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.http.post(`/api/intervention-static-step/${!_.isNil(this.model.id) ? this.model.id + "/" : ""}`, this.model)
                .then(response => this.dialog.hide(response.data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: InterventionStepEditDialogCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("../views/intervention-step-edit-dialog.html"),
    };
})();