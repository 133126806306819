(function () {
    'use strict';
    const CAM_CONFIG = require('../json/cam-config.json');

    class CamConfig {
        constructor($state, $mdToast, $translate, printService, interventionService) {
            this.$state = $state;
            this.$mdToast = $mdToast;
            this.$translate = $translate;
            this.printService = printService;
            this.interventionService = interventionService;
            this.editItem = this.editItem.bind(this);
            this.deleteItem = this.deleteItem.bind(this);
        }

        static get $inject() {
            return ["$state", "$mdToast", "$translate", "printService", "interventionService"];
        }

        $onInit() {
            this.configs = CAM_CONFIG;
        }

        editItem(e) {
            this.$state.go('app.cam.intervention-form', {modelId: e.id}, {replace: true});
        }

        deleteItem(e) {
            this.promise = this.interventionService.deleteIntervention(e.id).then(this.reset, (err) => {
                this.$mdToast.show(this.$mdToast.simple()
                    .textContent(this.$translate.instant(this.$translate.instant('impossible_delete'))
                        .position("bottom left")
                        .hideDelay(1500)));
            });
        }
    }

    module.exports = CamConfig;

})()