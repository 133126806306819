/**
 * Created by amine on 08/12/2017.
 */
(function () {
    'use strict';

    module.exports = mnSpecialPower;

    mnSpecialPower.$inject = ['authService'];

    function mnSpecialPower(authService) {
        function linkFunc(scope, $element, $attrs) {
            scope.$watch($attrs['mnSpecialPower'], handleItem);

            function handleItem() {
                const specialPower = scope.$eval($attrs['mnSpecialPower']);

                if (specialPower && !authService.hasSpecialPower()) $element.hide();
                else $element.show();
            }

        }

        return {
            restrict: 'A',
            link: linkFunc
        };
    }

})();
