/**
 * Edited by amine on 12/05/2022.
 */
(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");

    class ConsultationBodyCtrl {
        constructor(consultationService, $transition$, $state, $q, $scope, moment, system) {
            this.consultationService = consultationService;
            this.state = $state;
            this.q = $q;
            this.scope = $scope;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            const params = $transition$.params('to');

            this.currentCopy = _.get(params, 'copy');
            this.template = _.get(params, 'template');
            this.currentVisit = _.get(params, 'visitId');
            this.currentConsultation = _.get(params, 'consultation');
            this.isLast = _.get(params, 'is-last');
            this.readOnly = _.get(params, 'read-only');
            this.currentPatient = _.get(params, 'pId');

            this.promise = null;
            this.lockSubscription = null;
            this.titles = [];
            this.consultationSaved = new BehaviorSubject(null);
            this.isLocked = false;

            this.handleAutoSave = _.mnDelay(() => this.preSave(), 750);
        }

        static get $inject() {
            return ["consultationService", "$transition$", "$state", "$q", "$scope", "moment", "system"];
        }

        $onInit() {
            let promises = [
                this.consultationService.getConfigItem(this.template)
            ];

            if (!!this.currentConsultation) {
                promises.push(this.consultationService.getConsultation(this.currentConsultation));
            } else if (!!this.currentCopy) {
                promises.push(this.consultationService.getConsultation(this.currentCopy));
            } else {
                this.consultation = promises.push({
                    visit_id: this.currentVisit,
                    consultation_date: this.moment().format(this.dateFormat),
                    pathologies: []
                });
            }

            this.promise = this.q.all(promises)
                .then(data => {
                    this.startFromTop = true;
                    this.consultationConfig = data[0];
                    this.consultation = _.isNull(this.currentCopy) ? data[1] : _.assign(
                        {
                            visit_id: this.currentVisit,
                            consultation_date: this.moment().format(this.dateFormat),
                        },
                        _.pick(data[1], 'blocks')
                    );

                    this.consultationSaved.next(_.isNull(this.currentConsultation) ? null : this.currentConsultation)

                    this.consultationService.currentSubscription.next({
                        currentActiveConsultId: !_.isNull(this.currentConsultation) && this.readOnly ? this.currentConsultation : -1,
                        currentEditConsult: !_.isNull(this.currentConsultation) && !this.readOnly ? [this.currentConsultation, false] : -1,
                        currentConsultationDate: _.get(this.consultation, "consultation_date")
                    });

                    if (!_.isNull(this.currentCopy)) this.preSave();
                });

            this.lockSubscription = this.consultationService.lockSubject
                .subscribe(item => {
                    this.isLocked = item.lock;
                    this.scope.$applyAsync();
                });
        }

        $onDestroy() {
            if (!!this.lockSubscription) this.lockSubscription.unsubscribe();
        }

        preSave() {
            return this.consultationService
                .autoSave(this.consultation, this.currentVisit, this.currentPatient, this.consultationConfig)
                .then(data => {
                    if (!this.consultation.id) {
                        this.consultation.id = data.id;

                        this.consultationService.newItemSubject.next(data.id);

                        this.state.go("app.visit.consultation.body", {
                            copy: null,
                            consultation: data.id,
                        }, {location: 'replace'});
                    }

                    this.consultationSaved.next(data.id);
                });
        }

        lock() {
            this.consultationService.lockSubject
                .next({lock: !this.isLocked});
        }

    }

    module.exports = ConsultationBodyCtrl;

})();
