(function () {
        'use strict';

        class equipmentService {
            constructor($q, $http, mnWebSocket, Upload) {
                this.q = $q;
                this.http = $http;
                this.ws = mnWebSocket;
                this.Upload = Upload;
            }

            static get $inject() {
                return ["$q", "$http", "mnWebSocket", "Upload"];
            }

            getEquipments() {
                const deferred = this.q.defer();
                let url = `/api/equipment/`;
                this.http.get(url)
                    .then(success, deferred.reject);

                function success(response) {
                    deferred.resolve(response.data);
                }

                return deferred.promise;
            }

            getEquipment(pk) {
                const deferred = this.q.defer();
                let url = `/api/equipment/${pk}/`;
                this.http.get(url)
                    .then(success, deferred.reject);

                function success(response) {
                    deferred.resolve(response.data);
                }

                return deferred.promise;
            }

            saveEquipment(e, file) {
                let deferred = this.q.defer();
                let url = `/api/equipment/${!_.isNil(e.id) ? e.id + "/" : ""}`;

                this.Upload.upload({url: url, data: {data: this.Upload.json(e), file: file, file_name: "picture"}})
                    .then(successCallback, errorCallback);

                function successCallback(response) {

                    deferred.resolve(response.data);
                }

                function errorCallback(err) {
                    deferred.reject(err);
                }

                return deferred.promise;
            }

            updateEquipmentOwner(pk, equipment_owner) {
                return this.ws.call('cam.Equipment.set_current_owner', {pk, equipment_owner});
            }

            deleteEquipment(pk) {
                let deferred = this.q.defer();
                let url = `/api/equipment/${pk}/`;

                this.http.delete(url)
                    .then(success.bind(this), deferred.reject);

                function success(response) {
                    deferred.resolve(response.data);
                }

                return deferred.promise
            }

            deleteExploitation(pk) {
                let deferred = this.q.defer();
                let url = `/api/equipment-exploitation/${pk}/`;

                this.http.delete(url)
                    .then(success.bind(this), deferred.reject);

                function success(response) {
                    deferred.resolve(response.data);
                }

                return deferred.promise
            }

            getExploitations(equipment) {
                let deferred = this.q.defer();
                let url = `/api/equipment-exploitation/?equipment=${equipment}`;

                this.http.get(url)
                    .then(success.bind(this), deferred.reject);

                function success(response) {
                    deferred.resolve(response.data);
                }

                return deferred.promise
            }
        }

        module.exports = equipmentService;
    }
)
();
