/**
 * Created by BETALOS on 26/02/2016.
 */
(function () {

    'use strict';

    class DateConfig {
        constructor($mdDateLocaleProvider, system, moment) {
            this.dateFormat = system['date_format'].js;

            moment.locale(system.lang);

            $mdDateLocaleProvider.months = moment.localeData().months();
            $mdDateLocaleProvider.shortMonths = moment.localeData().monthsShort();
            $mdDateLocaleProvider.days = moment.localeData().weekdays();
            $mdDateLocaleProvider.shortDays = moment.localeData().weekdaysShort();
            $mdDateLocaleProvider.firstDayOfWeek = moment.localeData()['_week']['dow'];

            $mdDateLocaleProvider.formatDate = (date) => {
                if (date) return moment(date).format(this.dateFormat);
                return null;
            };

            $mdDateLocaleProvider.parseDate = (dateString) => {
                let m = null;

                if (_.isString(dateString)) m = moment(dateString, this.dateFormat, true);
                else m = moment(dateString);

                return m.isValid() ? m.toDate() : new Date(NaN);
            };
        }

        static create() {
            return new DateConfig(...arguments);
        }
    }

    DateConfig.create.$inject = ["$mdDateLocaleProvider", "system", "moment"];

    module.exports = DateConfig.create;

})();
