(function () {

    'use strict';

    class ContractGuestDialogCtrl {
        constructor($mdDialog, $http, mnWebSocket) {
            this.http = $http;
            this.mnWebSocket = mnWebSocket;
            this.dialog = $mdDialog;
            this.preventSave = false;
            this.model = {guest_count: 1, anonymous: false};
            this.guests = [];
        }

        static get $inject() {
            return ["$mdDialog", "$http", "mnWebSocket"];
        }

        $onInit() {
            this.current_pointing = this.current_pointing ? this.current_pointing : null;
            if (this.current_pointing) {
                this.promise = this.http.get(`/api/contract-pointing/${this.current_pointing}/`).then(data => {
                    this.guests = _.get(data, 'data.guests', [_.cloneDeep(this.model)]);
                    if (_.isEmpty(this.guests)) {
                        this.guests = [_.cloneDeep(this.model)];
                    }
                });
            } else {

                this.promise = this.mnWebSocket.call('billing.Contract.get_current_guests').then(data => {
                    this.guests = data;
                });
            }
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            if (!this.preventSave) {
                this.http.put(`/api/contract-pointing/${this.current_pointing}/`, {guests: this.guests})
                    .then(response => {
                        this.preventSave = false;
                        this.dialog.hide(response.data)
                    });
            }
        }

        handleGuestCountChange(obj, value) {
            if (value > 1 && (obj.first_name || obj.last_name)) {
                obj.first_name = null;
                obj.last_name = null;
                obj.anonymous = true;
            }
        }

        addItem() {
            this.guests.push(_.cloneDeep(this.model));
        }

        deleteItem(e, idx) {
            this.guests.splice(idx, 1);
        }
    }

    module.exports = {
        template: require("../views/contract-guest-edit-dialog.html"),
        controllerAs: "vm",
        controller: ContractGuestDialogCtrl,
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    }

})();