/**
 * Created by Hp on 05/08/2017.
 */

(function () {

    'use strict';

    module.exports = formatNumber;

    formatNumber.$inject = [];

    function formatNumber() {

        var directive = {
            restrict: "A",
            require: "?ngModel",
            scope: {
                formatString: "@",
            },
            link: linkFunc,
        };

        function linkFunc(scope, element, attr, ngModel) {
            var formatString = scope.formatString || "";

            ngModel.$render = function () {
                if (ngModel.$modelValue != null && ngModel.$modelValue >= 0) {
                    element.val(ngModel.$modelValue.toString().concat(" " + formatString));
                }
            }
            ngModel.$parsers.unshift(function (newValue) {
                return parseInt(newValue.replace(formatString, ""));
            });

            element.on("change", function (e) {
                var intValue = parseInt(element.val().replace(formatString, ""));
                if (_.isNil(intValue)) intValue = 0
                if (!_.isNil(intValue)) {
                    element.val(intValue.toString().concat(" " + formatString));
                }
            });

            element.on("click focus", function (e) {
                this.focus();
                this.setSelectionRange(0, element.val().length - formatString.length);
            });


            element.css('text-align', 'right');
        };
        return directive;
    };
})()