(function () {

    'use strict';

    class GeneratorDialogCtrl {
        constructor($mdDialog, $http, camConfigService, $translate, system, $scope) {
            this.http = $http;
            this.dialog = $mdDialog;
            this.camConfigService = camConfigService;
            this.$translate = $translate;
            this.system = system;
            this.$scope = $scope;
            this.dateFormat = this.system['date_format'].js
            this.url = this.url || null;
            this.model = this.model || {equipments: [], next_period_date: moment().format(this.dateFormat)};
            this.forceDisabled = false;
            this.equipmentsQuery = {
                limit: 5,
                page: 1,
                order: "+code"
            };
            this.paginationLabel = {
                page: this.$translate.instant('page'),
                rowsPerPage: this.$translate.instant('rowsPerPage'),
                of: this.$translate.instant('of')
            };
        }

        static get $inject() {
            return ["$mdDialog", "$http", "camConfigService", "$translate", "system", "$scope"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.camConfigService.saveItem(this.model, this.url).then(data => {
                this.model = data;
            });
        }

        selectEquipment(ev) {
            this.dialog.show(_.assign(require('cam/dialogs/cam-document-list-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {is_deleted: {$ne: true}},
                    headerIcon: 'mdi-inbox',
                    headerLabel: 'equipments',
                    tableModel: 'cam.Equipment'
                }
            })).then(data => {
                this.model.equipments = _.chain(this.model.equipments).concat(data).uniqBy('id').value();
            });
        }

        removeItem(idx) {
            this.model.equipments.splice(idx, 1);
        }

        onPaginateEquipments(page, limit) {
            this.equipmentsQuery = _.assign(this.equipmentsQuery, {page: page, limit: limit});
            if (this.model.equipments.length < ((this.equipmentsQuery.page - 1) * this.equipmentsQuery.limit)) {
                this.equipmentsQuery.page = 1;
            }
        }

        handleActivate(active = false) {
            this.promise = this.camConfigService.handle_activation(this.model.id, active).then(data => {
                this.model = data;
            });
        }

    }

    module.exports = {
        locals: {},
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: GeneratorDialogCtrl,
        focusOnOpen: false,
        template: require("../views/generator-edit-dialog.html"),

    };
})();