/**
 * Created by Hp on 04/05/2017.
 */
(function () {
    'use strict';

    const VisitSearchDialog = {
        controller: VisitSearchDialogCtrl,
        controllerAs: "vm",
        template: require("billing/views/visit-search-modal.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true
    };

    VisitSearchDialogCtrl.$inject = ["$mdDialog", "paymentService", "$translate"];

    function VisitSearchDialogCtrl($mdDialog, paymentService, $translate) {
        let vm = this;
        vm.$onInit = init;
        vm.cancel = $mdDialog.cancel;

        vm.selected = [];
        vm.visitQuery = {
            limit: 10,
            page: 1,
            order: "-visit_date",
            hide_paid: false,
            hide_no_amount: true,
            patientFilter: {}
        };

        vm.paginationLabel = {
            page: $translate['instant']('page'),
            rowsPerPage: $translate['instant']('rowsPerPage'),
            of: $translate['instant']('of')
        };

        vm.options = [10, 20, 100];
        vm.selectedVisit = null;
        vm.visits = [];

        vm.addSelection = addSelection;
        vm.onVisitPaginate = onVisitPaginate;
        vm.showProcedures = showProcedures;
        vm.onVisitReorder = onVisitReorder;
        vm.getData = getData;


        function init() {
            getVisitData();
        }

        function getVisitData() {
            if (vm.beneficiary_type === 'PATIENT')
                vm.visitPromise = paymentService
                    .getVisits(_.assign(vm.visitQuery, {patient: vm.beneficiary.id, only_patient: true}))
                    .then(done);
            if (vm.beneficiary_type === 'ORGANIZATION')
                vm.visitPromise = paymentService
                    .getOrganizationVisits(_.assign(vm.visitQuery, {
                        organization: vm.beneficiary.id,
                        only_organization: true
                    }))
                    .then(done);

            function done(data) {
                vm.selectedVisit = null;
                vm.visitTotal = data.length;
                vm.visits = data.list;
                if (vm.visitTotal < ((vm.visitQuery.page - 1) * vm.visitQuery.limit)) {
                    vm.visitQuery.page = 1;
                }
            }
        }


        function onVisitPaginate(page, limit) {
            vm.visitQuery = _.assign(vm.visitQuery, {page: page, limit: limit});
            getVisitData();
        }

        function addSelection() {
            $mdDialog.hide(vm.selected);
        }

        function showProcedures(visit, $event) {
            $event.stopPropagation();

            if (vm.selectedVisit === visit.id) vm.selectedVisit = -1;
            else vm.selectedVisit = visit.id;
        }

        function onVisitReorder(order) {
            vm.visitQuery = _.assign(vm.visitQuery, {order: order});
            getVisitData();
        }

        function getData() {
            if (vm.beneficiary_type === 'ORGANIZATION') getVisitData();
        }

    }

    module.exports = VisitSearchDialog;

})();