(function () {

    'use strict';

    class camConfigItemDialogCtrl {
        constructor($mdDialog, $http, camConfigService, $scope) {
            this.http = $http;
            this.dialog = $mdDialog;
            this.camConfigService = camConfigService;
            this.$scope = $scope;
            this.url = this.url || null;
            this.model = this.model || null;
            this.forceDisabled = false;
        }

        static get $inject() {
            return ["$mdDialog", "$http", "camConfigService", "$scope"];
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.camConfigService.saveItem(this.model, this.url).then(response => this.dialog.hide(response.data));
        }

    }

    module.exports = {
        locals: {},
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: camConfigItemDialogCtrl,
        focusOnOpen: false
    };
})();