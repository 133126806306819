(function () {

    'use strict';

    class LineCounterDirective {
        constructor($timeout) {
            this.priority = 0;
            this.restrict = "A";
            this.timeout = $timeout;
        }

        static create() {
            return new LineCounterDirective(...arguments);
        }

        link(scope, element, attrs) {
            let watchFunc = scope.$watch(
                () => element.html() && element.is(':visible'), () => this.handleLineCount(element, attrs)
            );

            scope.$on('$destroy', () => watchFunc && watchFunc());
        }

        handleLineCount(element, attrs) {
            this.timeout(() => {
                const elementHeight = element.get(0).offsetHeight;
                const lineHeight = _.chain(attrs).get('lineCounter').toInteger().value();
                const lineCount = Math.round(elementHeight / lineHeight);

                element.removeClass((index, className) => (className.match(/\bline-\S+/g) || []).join(' '));
                element.addClass(`line-${lineCount}`);
            }, 75);
        }
    }

    LineCounterDirective.create.$inject = ["$timeout"];

    module.exports = LineCounterDirective.create;

})()