/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {

    'use strict';

    module.exports = consultationService;

    const {Subject, BehaviorSubject} = require("rxjs");

    consultationService.$inject = ["$q", "$http", "mnWebSocket", "$mdDialog", "$translate"];

    function consultationService($q, $http, mnWebSocket, $mdDialog, $translate) {
        const self = this;
        const editSubject = new Subject();

        self.newItemSubject = new Subject();
        self.currentSubscription = new Subject();
        self.lockSubject = new BehaviorSubject({lock: true});

        self.getConfig = getConfig;
        self.getConfigItem = getConfigItem;
        self.getConfigTable = getConfigTable;

        self.saveConfig = saveConfig;

        self.getConsultation = getConsultation;
        self.getConsultationList = getConsultationList;
        self.getConsultationListItem = getConsultationListItem;
        self.getVisitConsultationList = getVisitConsultationList;

        self.autoSave = autoSaveConsultation;
        self.removeConsultation = removeConsultation;

        self.favoriteSubject = favoriteSubject;
        self.newItemSubscription = newItemSubscription;

        // mobile consultation
        self.getMobileConsultations = getMobileConsultations;
        self.updateMobileConsultation = updateMobileConsultation;
        self.removeMobileConsultation = removeMobileConsultation;
        self.getMobileConsultationDetail = getMobileConsultationDetail;
        self.getMobileConsultationTemplate = getMobileConsultationTemplate;
        self.saveMobileConsultationTemplate = saveMobileConsultationTemplate;


        function getConfig() {
            const deferred = $q.defer();
            const url = "/api/consultation-config-table/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getConfigItem(config) {
            const deferred = $q.defer();
            const url = `/api/consultation-config/${config}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                response.data['procedures'] = _.map(response.data['procedure_ids'], id => new Object({id}));
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getConfigTable() {
            const deferred = $q.defer();
            const url = "/api/consultation-config-table/";

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function saveConfig(template) {
            const deferred = $q.defer();
            const url = "/api/consultation-config/";

            $http.post(url, _.omit(template, 'id'))
                .then(success, deferred.reject);

            function success(response) {
                response.data['procedures'] = _.map(response.data['procedure_ids'], id => new Object({id}));
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getConsultation(consultationId) {
            const deferred = $q.defer();
            const url = `/api/consultation/${consultationId}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function autoSaveConsultation(consultation, visit, patient, template) {
            const deferred = $q.defer();
            let url = `/api/consultation/${consultation.id ? consultation.id + '/' : ''}?patient=${patient}`;


            if (consultation.id) {
                $http.put(url, consultation).then(success, deferred.reject);
            }
            else {
                if (!_.has(consultation, "blocks")) consultation.blocks = {};
                mnWebSocket.call('visit.VisitPayment.set_visit_procedures', {
                    visit, procedures: template['procedure_ids']
                }).then(startSave, deferred.reject);
            }

            function startSave(procedures_uid) {
                _.assign(consultation, {
                    visit: {id: visit},
                    template: _.pick(template, 'id'),
                    procedures_uid: procedures_uid
                });

                $http.post(url, consultation).then(success, deferred.reject);
            }

            function success(response) {
                editSubject.next(response.data);
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getConsultationList(patient) {
            const deferred = $q.defer();
            const url = `/api/consultation-list/?patient=${patient}`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getVisitConsultationList(visit) {
            return mnWebSocket.call('consultation.Consultation.full_list', {visit: _.isObject(visit) ? visit.id : visit})
        }

        function getConsultationListItem(id) {
            const deferred = $q.defer();
            const url = `/api/consultation-list/${id}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeConsultation(consultation, patient, ev) {
            const deferred = $q.defer();

            confirmDialog(ev).then(remove, deferred.reject);

            function remove() {
                const url = `/api/consultation/${consultation.id}/?patient=${patient}`;
                const data = {
                    visit: consultation.visit_id, procedures_uid: consultation.procedures_uid
                };

                $q.all([
                    $http.delete(url),
                    mnWebSocket.call('visit.VisitPayment.remove_visit_procedures', data)
                ]).then(deferred.resolve);
            }

            return deferred.promise;
        }

        function favoriteSubject(callback) {
            return editSubject.subscribe({next: callback});
        }

        function newItemSubscription(callback) {
            return self.newItemSubject.subscribe({next: callback});
        }


        function confirmDialog(ev) {
            const confirm = $mdDialog.confirm()
                .targetEvent(ev)
                .ariaLabel('remove plan confirm')
                .ok($translate['instant']('confirm_ok'))
                .cancel($translate['instant']('confirm_cancel'))
                .title($translate['instant']('consultation_remove_confirm'));

            return $mdDialog.show(confirm);
        }


        // mobile consultation
        function getMobileConsultations(patient) {
            const deferred = $q.defer();
            const url = `/api/mobile-consultation-list/?patient=${patient}`;

            $http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function updateMobileConsultation(instance) {
            const deferred = $q.defer();
            const url = `/api/mobile-consultation/${instance.id}/`;

            $http.put(url, {block: instance.block})
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function removeMobileConsultation(consultation, ev) {
            const deferred = $q.defer();

            confirmDialog(ev).then(remove, deferred.reject);

            function remove() {
                const url = `/api/mobile-consultation/${consultation.id}/`;
                $http.delete(url).then(deferred.resolve, deferred.reject);
            }

            return deferred.promise;
        }

        function getMobileConsultationDetail(instance) {
            const deferred = $q.defer();
            const url = `/api/mobile-consultation/${instance}/`;

            $http.get(url)
                .then(response => deferred.resolve(response.data), deferred.reject);

            return deferred.promise;
        }

        function getMobileConsultationTemplate() {
            const deferred = $q.defer();
            const url = '/api/mobile-consultation-config/';

            $http.get(url)
                .then(
                    response => deferred.resolve(response.data.length > 0 ? response.data[0] : {fields: []}), deferred.reject
                );

            return deferred.promise;
        }


        function saveMobileConsultationTemplate(template) {
            const deferred = $q.defer();
            const url = '/api/mobile-consultation-config/';

            $http.post(url, _.omit(template, 'id'))
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

    }

})();
