/**
 * Created by Amine on 16/08/2016.
 */
(function () {

    var angular = require('angular');

    var CertificateContainerCtrl = require("./controllers/CertificateContainerCtrl");
    var CertificateFormCtrl = require("./controllers/CertificateFormCtrl");
    var CertificateListCtrl = require("./controllers/CertificateListCtrl");
    var CertificateViewCtrl = require("./controllers/CertificateViewCtrl");
    var certificateService = require("./services/certificateService");
    var mnCertificateItem = require("./components/certificate-item");

    angular
        .module("medinet")

        .component("mnCertificateItem", mnCertificateItem)

        .controller('CertificateContainerCtrl', CertificateContainerCtrl)
        .controller('CertificateFormCtrl', CertificateFormCtrl)
        .controller('CertificateListCtrl', CertificateListCtrl)
        .controller('CertificateViewCtrl', CertificateViewCtrl)

        .service("certificateService", certificateService);
})();