/**
 * Created by amine on 28/03/2017.
 */
(function () {
    'use strict';

    class ScrollSref {
        constructor(scrollService) {
            this.restrict = "A";
            this.scrollService = scrollService;
        }

        static create() {
            return new ScrollSref(...arguments);
        }


        link(scope, element, attrs) {
            const ref = attrs['mnScrollSref'].split(":");

            element.on("click", (event) => {
                this.scrollService['sref'](ref[0], ref[1], event);
            });
        }
    }

    ScrollSref.create.$inject = ["scrollService"];

    module.exports = ScrollSref.create;

})();