(function () {

    'use strict';

    class PecAnswerDialogCtrl {
        constructor($mdDialog, $translate, system) {
            this.system = system;
            this.$mdDialog = $mdDialog;
            this.dateFormat = this.system['date_format'].js;
            this.cancel = $mdDialog.cancel;
        }

        static get $inject() {
            return ["$mdDialog", "$translate", "system"];
        }

        $onInit() {
            this.answers = [
                'filed_application',
                'agreement_subject',
                'request_final_agreement_in_progress',
                'final_agreement_received',
                'request_for_further_information',
                'rejection',
                'unmanaged_folder'
            ];
            this.details = this.details || [];
            this.answer = !_.isNil(this.answer) ? this.answer : {
                date: moment().format(this.dateFormat),
                confirmed_amount: 0
            };
        }

        save() {
            this.$mdDialog.hide({answer:this.answer, details:this.details});
        }

        onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
            if (this.details.length < ((this.query.page - 1) * this.query.limit)) {
                this.query.page = 1;
            }
        }
    }

    module.exports = {
        template: require("../views/pec-answer-dialog.tpl.html"),
        controllerAs: "vm",
        controller: PecAnswerDialogCtrl,
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true,
        multiple: true
    }

})();