/**
 * Created by amine on 28/03/2017.
 */
(function () {
    'use strict';

    class Scroll {
        constructor(scrollService, $timeout) {
            this.restrict = "A";
            this.$timeout = $timeout;
            this.scrollService = scrollService;
        }

        static create() {
            return new Scroll(...arguments);
        }

        compile(element, attrs) {
            this.scrollService.registerContainer(
                attrs['mnScroll'], (data) => this.onNext(element, data)
            );

            return this.link;
        }

        link(scope, element, attrs) {
            let timeout = null;

            let unwatch = scope.$watch(() => element.prop('scrollHeight'), _.mnDelay(() => {
                let activeScroll = $("[scroll-active=true]", element);
                if (activeScroll.length > 0) {
                    if (!_.isNil(timeout)) this.$timeout.cancel(timeout);

                    this.scrollTo(activeScroll, element);
                    timeout = this.$timeout(unwatch, 500);
                }
            }, 500));
        }

        onNext(element, item) {
            if (!_.isEmpty(item)) {
                let scrollElement = $(`[mn-scroll-item='${item}']`, element);
                this.scrollTo(scrollElement, element);
            }
        }

        scrollTo(item, element) {
            if (_.isNil(item.position())) {
                element.scrollTop(0);
                return false;
            }

            const itemTop = item.position().top;
            const elementTop = element.scrollTop();

            element.stop().animate({scrollTop: elementTop + itemTop}, {
                duration: 500
            });
        }
    }

    Scroll.create.$inject = ["scrollService", "$timeout"];

    module.exports = Scroll.create;

})();
