/**
 * Created by Amine on 15/03/2017.
 */
(function () {
    'use strict';

    class Validate {
        constructor($parse) {
            this.$parse = $parse;

            this.restrict = "A";
            this.require = "form";

            this.scope = {
                validators: "=mnValidate"
            }
        }

        static create() {
            return new Validate(...arguments);
        }

        link(scope, element, attrs, ctrl) {
            scope.$watch(() => {
                return scope.validators
            }, validators => {
                _.forEach(validators, (value, name) => {
                    ctrl.$setValidity(name, value);
                });
            });
        }
    }

    Validate.create.$inject = ["$parse"];

    module.exports = Validate.create;

})();