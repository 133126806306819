/**
 * Created by Amine on 28/07/2016.
 */
(function () {
    'use strict';

    class NumberCtrl {
        constructor(system, $element, $attrs, $timeout, $translate) {
            this.attrs = $attrs;
            this.element = $element;
            this.timeout = $timeout;
            this.translate = $translate;

            this.toFix = 0;
            this.min = NaN;
            this.max = NaN;
            this.observations = {};
            this.pattern = "[0-9]+";
            this.currencyFloat = system['currency_float'];
            this.labelTranslated = "";
        }

        static get $inject() {
            return ["system", "$element", "$attrs", "$timeout", "$translate"];
        }

        $onInit() {
            let float = false;
            let currency = false;
            let percentage = false;

            this.empty = _.isUndefined(this.empty) ? false : this.empty;
            this.type = _.isUndefined(this.type) ? "integer" : this.type;
            this.change = _.isFunction(this.change) ? this.change : _.noop;

            this.labelTranslated = generateLabel(this.translate, this.attrs['label'], this.type);

            switch (this.type) {
                case "percentage":
                    float = percentage = true;
                    break;
                case "currency":
                    float = currency = true;
                    break;
                case "float":
                    float = true;
                    break;
            }

            if (float) {
                this.toFix = currency ? this.currencyFloat : "2";
                this.pattern += `\\.[0-9]{${this.toFix}}`;
            }

            if (percentage) this.max = 100;
            if (currency || percentage) this.min = 0;

            this.observations.min = this.attrs.$observe("min", min => this.min = parseFloat(min));
            this.observations.max = this.attrs.$observe("max", max => this.max = parseFloat(max));
            this.observations.label = this.attrs.$observe("label", newValue => {
                this.labelTranslated = generateLabel(this.translate, newValue, this.type);
            });
        }

        $onDestroy() {
            _.forOwn(this.observations, func => func());
        }

        valueChanged() {
            this.timeout(() => this.change());
        }
    }

    tpl.$inject = ['$element', '$attrs', '$translate'];

    function tpl(elem, attrs, $translate) {
        const disabled = _.isNil(attrs.disabled) ? '' : 'disabled';
        const required = _.isNil(attrs['required']) ? '' : 'required';
        const empty = _.isNil(attrs['empty']) ? "false" : attrs['empty'];
        const ngDisabled = _.isNil(attrs['ngDisabled']) ? '' : 'ng-disabled="vm.disabled"';

        const allowZero = _.isNil(attrs['allowZero']) ? "true" : attrs['allowZero'];

        const focus = elem.is('[md-autofocus]') ? 'md-autofocus' : '';
        const modelOptions = elem.is('[ng-model-options]') ? 'ng-model-options="{updateOn: \'blur\'}"' : '';
        const mdAutocomplete = elem.is('[md-autocomplete]') ? 'md-autocomplete=' : '';

        const tooltip = !elem.is('[no-tooltip]');
        const step = _.isNil(attrs['step']) ? '' : `mn-step="${attrs['step']}"`;

        elem.addClass("layout-row");

        return [
            '<md-input-container class="flex">',
            `   <label ng-bind="vm.labelTranslated"></label>`,
            `   <input ng-change="vm.valueChanged()" ng-model="vm.model" type="number" ng-pattern="::vm.pattern"
                min="{{vm.min}}" max="{{vm.max}}" ${focus} ${required} ${disabled} ${ngDisabled} ${modelOptions} ${mdAutocomplete}
                mn-number="{{::vm.toFix}}" mn-type="{{::vm.type}}" mn-empty="${empty}" mn-allow-zero="${allowZero}" ${step}/>`,
            `   ${tooltip ? `<md-tooltip md-direction="bottom" md-delay="150" ng-if="vm.labelTranslated" ng-bind="vm.labelTranslated"></md-tooltip>` : ''}`,
            '</md-input-container>'
        ].join('');

    }

    function generateLabel($translate, label, type) {
        return `${$translate.instant(label)}${type == "percentage" ? '(%)' : ''}`;
    }

    module.exports = {
        template: tpl,
        controllerAs: "vm",
        controller: NumberCtrl,
        require: {ngModelController: "ngModel"},
        bindings: {
            type: '@',
            empty: '<?',
            model: "=ngModel",
            change: "&ngChange",
            disabled: "=ngDisabled",
            discount: "=?mnDiscount"
        }
    };

})();