(function () {

    'use strict';

    class SrcBlobLoaderDirective {
        constructor(uploadService) {
            this.priority = 0;
            this.restrict = "A";
            this.uploadService = uploadService;

            this.scope = {
                "error": '=?',
                "srcBlob": '<',
            };
        }

        static create() {
            return new SrcBlobLoaderDirective(...arguments);
        }

        link(scope, element) {
            element.on('load', () => this.triggerLoad(scope, element));
            element.on('error', () => this.triggerError(scope, element));
            scope.$watch("srcBlob", value => value ? this.handleChange(value, element) : this.triggerError(scope, element));
        }

        triggerLoad(scope, element) {
            element.show();
            scope.$applyAsync(() => scope.error = false);
        }

        triggerError(scope, element) {
            element.hide();
            scope.$applyAsync(() => scope.error = true);
        }

        handleChange(src, element) {
            this.uploadService.downloadFileAsBlob(src)
                .then(blob => element.attr('src', blob));
        }
    }

    SrcBlobLoaderDirective.create.$inject = ["uploadService"];

    module.exports = SrcBlobLoaderDirective.create;

})()