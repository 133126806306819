/**
 * Created by BETALOS on 10/10/2016.
 */
(function () {

    'use strict';

    const {HTMLS} = require('shared/utils/requires');

    class BindHtml {
        constructor($compile) {
            this.priority = 0;
            this.restrict = "AE";
            this.$compile = $compile;
        }

        static create() {
            return new BindHtml(...arguments);
        }

        compile(element, attrs) {
            if (element.is("mn-bind-html")) {
                if (!_.isNil(attrs["view"])) {
                    let template = HTMLS[attrs["view"]];
                    element.html(template);
                }
            } else {
                let template = HTMLS[attrs["mnBindHtml"]];
                element.html(template);
            }

            return this.link;
        }

        link($scope, $element, $attrs) {
            let childScope = null;
            let observe = $scope.$eval($attrs.observe);

            if ($element.is("mn-bind-html[mn-view]")) {
                $attrs.$observe("mnView", (value) => {
                    if (_.isEmpty(value)) return;

                    let template = HTMLS[value];
                    let directiveElement = this.compileDirective(childScope, template, $scope);

                    $element.html(directiveElement);
                });
            }

            else if ($element.is("[mn-view-callback]") && !observe) {
                const callbackResult = $scope.$eval($attrs.mnViewCallback);
                let directiveElement = this.compileDirective(childScope, callbackResult, $scope);

                $element.html(directiveElement);
            }

            else if (observe) {
                $scope.$watch($attrs.observable, () => {
                    const callbackResult = $scope.$eval($attrs.mnViewCallback);
                    let directiveElement = this.compileDirective(childScope, callbackResult, $scope);

                    $element.html(directiveElement);
                });
            }
        }

        compileDirective(childScope, template, $scope) {
            if (childScope) childScope.$destroy();

            childScope = $scope.$new();
            let compiledDirective = this.$compile(template);
            let directiveElement = compiledDirective(childScope);

            return directiveElement.length > 0 ? directiveElement : template;
        }
    }

    BindHtml.create.$inject = ['$compile'];

    module.exports = BindHtml.create;

})();
