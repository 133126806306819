/**
 * Created by Hp on 04/05/2017.
 */
(function () {
    'use strict';

    var TreatmentPlanDialog = {
        controller: TreatmentPlanDialogCtrl,
        controllerAs: "vm",
        template: require("billing/views/dental-treatment-plan-search.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: false,
        bindToController: true
    };

    TreatmentPlanDialogCtrl.$inject = ["$mdDialog", "paymentService", "$translate", "dentalService"];

    function TreatmentPlanDialogCtrl($mdDialog, paymentService, $translate, dentalService) {
        let vm = this;
        vm.$onInit = init;

        vm.cancel = $mdDialog.cancel;

        vm.selected = [];


        vm.planQuery = {
            limit: 10,
            page: 1,
            order: "-creation_date"
        }

        vm.paginationLabel = {
            page: $translate['instant']('page'),
            rowsPerPage: $translate['instant']('rowsPerPage'),
            of: $translate['instant']('of')
        }

        vm.options = [ 10, 20, 100];

        vm.addSelection = addSelection;
        vm.onPlanPaginate = onPlanPaginate;
        vm.onPlanReorder = onPlanReorder;




        function init() {
            vm.plans = [];

            if (vm.beneficiary_type == 'PATIENT') {
                vm.promise = dentalService.getPatientPlans(vm.beneficiary.id).then(success);
            }

            function success(data) {
                vm.plans = data;
                vm.planTotal = data.length;
                if (vm.planTotal < ((vm.planQuery.page - 1) * vm.planQuery.limit)) {
                    vm.planQuery.page = 1;
                }
            }
        }

        function onPlanPaginate(page, limit) {
            vm.planQuery = _.assign(vm.planQuery, {page: page, limit: limit});
        }

        function addSelection() {
            $mdDialog.hide(vm.selected);
        }

        function onPlanReorder(order) {
            vm.planQuery = _.assign(vm.planQuery, {order: order});
        }


    }

    module.exports = TreatmentPlanDialog;

})();