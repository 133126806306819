(function () {
    'use strict';

    window.$ = window.jQuery = require('jquery');

    window.moment = require('moment');
    require('moment/min/locales');

    window._ = require('lodash');

    window.tinycolor = require('tinycolor2');

    require('tinymce/tinymce');
    require('stand-alone/text-editor/utils/editior-plugins');

    require('amcharts3/amcharts/amcharts.js');
    require('amcharts3/amcharts/serial.js');
    require('amcharts3/amcharts/themes/light.js');
    require('amstock3/amcharts/amstock');

    require('webdatarocks/webdatarocks.toolbar.js');
    require('webdatarocks/webdatarocks.js');

    require('flexmonster/flexmonster.full.js');

    const angular = require('angular');

    require("./core/app");

    $.get("/api/system/", {}, data => {
        data.timezone = "UTC";
        data["date_format"].naive = "YYYY-MM-DDTHH:mm:ss";
        data["date_format"].calendar = "YYYY-MM-DDThh:mm:ssZZ";
        //moment.tz.guess();
        //moment.tz.setDefault("UTC");

        angular
            .module('medinet')
            .constant('system', data);

        angular.bootstrap(document, ['medinet']);
    });

})();

