/**
 * Created by Hp on 03/05/2017.
 */
(function () {

    var angular = require('angular');

    var InvoiceFormCtrl = require('./controllers/InvoiceFormCtrl');
    var QuotationFormCtrl = require('./controllers/QuotationFormCtrl');
    var BillingCtrl = require('./controllers/BillingCtrl');
    var ExpenseFormCtrl = require('./controllers/ExpenseFormCtrl');
    var ContractFormCtrl = require('./controllers/ContractFormCtrl');
    var PecCtrl = require('./controllers/PecCtrl');
    var PecFormCtrl = require('./controllers/PecFormCtrl');
    var BillingCreditNoteCtrl = require('./controllers/BillingCreditNoteCtrl');

    var mnPatientRelations = require('./components/patient-relations');

    var billingService = require('./services/billingService');

    var formatNumber = require('./directives/format-number');


    angular
        .module("medinet")

        .controller('InvoiceFormCtrl', InvoiceFormCtrl)
        .controller('QuotationFormCtrl', QuotationFormCtrl)
        .controller('BillingCtrl', BillingCtrl)
        .controller('ExpenseFormCtrl', ExpenseFormCtrl)
        .controller('ContractFormCtrl', ContractFormCtrl)
        .controller('PecCtrl', PecCtrl)
        .controller('PecFormCtrl', PecFormCtrl)
        .controller('BillingCreditNoteCtrl', BillingCreditNoteCtrl)

        .component('mnPatientRelations', mnPatientRelations)

        .directive('formatNumber', formatNumber)

        .service('billingService', billingService);


})();