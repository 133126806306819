(function () {
    'use strict';

    const {ROUTES, HTMLS} = require('shared/utils/requires');

    class RouteConfig {
        constructor($locationProvider, $stateProvider, $urlServiceProvider) {
            $locationProvider.html5Mode({enabled: true, requireBase: true});

            this.routes = ROUTES;
            this.$stateProvider = $stateProvider;
            this.$urlServiceProvider = $urlServiceProvider;

            this.setUpRoutes();
            this.defaultRoute();
        }

        static create() {
            return new RouteConfig(...arguments);
        }

        defaultRoute() {
            let profile = localStorage.getItem('profile');
            let otherwiseUrl = 'app.frontDesk.index';

            if (!_.isNil(profile)) {
                profile = JSON.parse(profile);
                otherwiseUrl = _.get(profile, 'default_state', otherwiseUrl) || otherwiseUrl;
            }

            this.$urlServiceProvider.rules.otherwise({state: otherwiseUrl});
        }

        setUpRoutes() {
            _.chain(this.routes).uniqBy('name').forEach((route) => this.injectRoute(route)).value();
        }

        injectRoute(value) {
            let state = _.pick(value, ['url', 'name', 'abstract', 'auth', 'sideNavLock']);

            if (value['abstract']) {
                state.template = HTMLS[`${value.template}`];
            }

            if (!value['abstract'] && !value.views) {
                state.controller = value.controller;
                state.controllerAs = value.controllerAs;

                state.template = HTMLS[`${value.template}`];
                state.data = _.pick(value, ['pageTitle', 'sideNavLock']);
            }

            if (!value['abstract'] && value.views) {
                state.views = this.getViews(value.views);
                state.data = _.pick(value, ['pageTitle', 'sideNavLock']);
            }

            if (value.params) {
                state.params = value.params;
            }

            this.$stateProvider.state(state.name, state);
        }

        getViews(views) {
            return _.mapValues(views, (value) => _.assign(value, {template: HTMLS[`${value.template}`]}));
        }
    }

    RouteConfig.create.$inject = ['$locationProvider', '$stateProvider', '$urlServiceProvider']

    module.exports = RouteConfig.create;

})();
