(function () {

    'use strict';

    class ProfileDialogCtrl {
        constructor($mdDialog, authService, $translate) {
            this.dialog = $mdDialog;
            this.service = authService;

            this.states = [
                {
                    "url": "app.frontDesk.index",
                    "label": $translate.instant('profile_dialog_agenda'),
                },
                {
                    "url": "app.patient",
                    "label": $translate.instant('profile_dialog_patient_list'),
                },
                {
                    "url": "app.visit_list",
                    "label": $translate.instant('profile_dialog_visit_list'),
                },
                {
                    "url": "app.workflow.daily-workflow",
                    "label": $translate.instant('profile_dialog_daily_workflow'),
                }
            ]
        }

        static get $inject() {
            return ["$mdDialog", "authService", "$translate"];
        }

        $onInit() {
            if (this.copy) this.promise = this.service.getProfile(this.profile.id)
                .then(data => {
                    this.profile = _.pick(data, ['permissions', 'default_state']);
                });

            else this.profile = this.profile ? _.pick(this.profile, ['id', 'name', 'default_state']) : {
                "default_state": "app.frontDesk.index"
            }
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.service[this.copy ? 'handleProfile' : 'handleProfilePartial'](this.profile)
                .then((data) => this.dialog.hide(data));
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ProfileDialogCtrl,
        template: require("../views/profile-dialog.tpl.html"),
    };


})();
