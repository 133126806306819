/**
 * Created by amine on 01/12/2018.
 */


(function () {
    'use strict';

    const {BehaviorSubject, take, skipWhile} = require("rxjs");

    class PrintSheetCtrl {
        constructor(careSheetService, printService, $element, $http, $scope, $mdToast, $translate) {
            this.careSheetService = careSheetService;
            this.printService = printService;
            this.$element = $element;
            this.$http = $http;
            this.$scope = $scope;
            this.$mdToast = $mdToast;
            this.$translate = $translate;

            this.gotModel = null;
            this.model = null;
            this.disabled = false;
            this.prePrint = null;
            this.$unwatch = _.noop;
            this.templates = [];
        }

        static get $inject() {
            return ["careSheetService", "printService", "$element", "$http", "$scope", "$mdToast", "$translate"];
        }

        $onInit() {
            this.gotModel = new BehaviorSubject(this.model);
            this.$element.addClass("layout-row");

            this.$unwatch = this.$scope.$watch("vm.model", () => {
                this.gotModel.next(this.model);
            });

            this.careSheetService.getCareSheetTemplates().then(data => this.templates = data)
        }

        $onDestroy() {
            this.$unwatch();
            this.gotModel.complete();
        }

        $onChanges(changes) {
            if (_.get(changes, "disabled.currentValue", false)) {
                $('.md-button', this.$element).attr('disabled', 'disabled')
            } else {
                $('.md-button', this.$element).removeAttr('disabled')
            }
            //this.handleDisabled(_.get(changes, "ngDisabled.currentValue", false));
        }

        printItem(template = null) {
            if (_.isUndefined(this.prePrint)) {
                return this.print(template);
            } else {
                return this.prePrint().then(() => {
                    this.gotModel
                        .pipe(
                            skipWhile(model => !model),
                            take(1)
                        )
                        .subscribe({
                            next: model => this.print(template),
                            onerror: _.noop
                        });
                }, _.noop);
            }
        }

        print(template) {
            const sheetId = this.gotModel.getValue();
            const templateQuery = _.isNil(template) ? "" : `template=${template}`;
            const url = `/api/care-sheet/${sheetId}/print-sheet/?${templateQuery}`;

            return this.printService.showPreview(url, sheetId).then(_.noop, error => {
                if (error.status === 404) {
                    const simpleToast = this.$mdToast.simple()
                        .textContent(this.$translate['instant']('care_sheet_template_error'))
                        .position("bottom left")
                        .hideDelay(2000);

                    this.$mdToast.show(simpleToast);
                }
            });
        }

    }

    module.exports = {
        controllerAs: "vm",
        bindings: {
            disabled: "<ngDisabled",
            model: "=",
            prePrint: "&?",
        },
        template: require('care-sheet/views/print-sheet.tpl.html'),
        controller: PrintSheetCtrl,
    };

})();
