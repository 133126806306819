/**
 * Created by Amine on 06/03/2016.
 */
(function () {

    'use strict';

    const {BehaviorSubject} = require("rxjs");

    class SidenavService {
        constructor(storageService) {
            this.subjects = {};
            this.storageService = storageService;
            this.sideNavSubject = new BehaviorSubject(null);
        }

        static get $inject() {
            return ['storageService'];
        }

        initSideNav(id) {
            if (!_.has(this.subjects, id)) {
                this.subjects[id] = new BehaviorSubject(false);
                this.subjects[id].opened = false;
            }
            return this.subjects[id];
        }

        destroySideNav(id) {
            this.subjects[id].complete();
            _.unset(this.subjects, id);
        }

        lastState(id) {
            if (!_.has(this.subjects, id)) this.initSideNav(id);

            const storageId = this.getStorageId(id);
            this.storageService.getKey(storageId)
                .then(value => {
                    const lastState = JSON.parse(value);
                    this.setNavStatus(this.subjects[id], _.isNull(lastState) ? true : lastState);
                });
        }

        open(id) {
            if (!_.has(this.subjects, id)) return false;

            this.setNavStatus(this.subjects[id], true);

            const storageId = this.getStorageId(id);
            this.storageService.setKey(storageId, JSON.stringify(true));
        }

        close(id, save = true) {
            if (!_.has(this.subjects, id)) return false;

            this.setNavStatus(this.subjects[id], false);

            if (save) {
                const storageId = this.getStorageId(id);
                this.storageService.setKey(storageId, JSON.stringify(false));
            }
        }

        toggle(id) {
            if (!_.has(this.subjects, id)) return false;

            this.setNavStatus(this.subjects[id], !this.subjects[id].opened);

            const storageId = this.getStorageId(id);
            this.storageService.setKey(storageId, JSON.stringify(this.subjects[id].opened));
        }

        setNavStatus(subject, status) {
            subject.opened = status;
            subject.next(status);
        }

        getStorageId(id) {
            return `side-nave-${id}`;
        }
    }

    module.exports = SidenavService;

})();
