(function () {

    'use strict';

    class StaffTokensDialogCtrl {
        constructor($mdDialog, $element) {
            this.dialog = $mdDialog;
            this.element = $element;

            this.showForm = false;

        }

        static get $inject() {
            return ["$mdDialog", "$element"];
        }

        $onInit() {
            this.tokens = this.tokens || [];
        }

        cancel() {
            this.dialog.cancel();
        }

        addToken() {
            this.showForm = true;
            this.form = {
                name: "",
                allowed_hosts: "127.0.0.1",
                token: this.#generateToken()
            }
        }

        insertToken() {
            this.showForm = false;
            const allowed_hosts = this.form.allowed_hosts.split(",").map(host => host.trim());
            this.tokens.push(_.assign({}, this.form, {allowed_hosts}));
        }

        submit() {
            this.dialog.hide(this.tokens);
        }

        selectToken() {
            $(".token-value-container", this.element).selectContentEditable();
        }

        removeToken(index) {
            this.tokens.splice(index, 1);
        }

        #generateToken() {
            const keys = "00112233445566778899abcdefghijklmnopqrstuvwxyz";

            return _.range(4)
                .map(() => {
                    return _.range(5).map(() => {
                        return keys.charAt(Math.floor(Math.random() * keys.length));
                    }).join("");
                })
                .join("-");
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: StaffTokensDialogCtrl,
        template: require("../views/tokens-dialog.tpl.html"),
    };

})();
