/**
 * Created by Amine on 28/07/2016.
 */
(function () {
    'use strict';

    class SideNavCtrl {
        constructor($element, service) {
            this.service = service;
            this.$element = $element;

            this.id = this.id ? this.id : null;
        }

        static get $inject() {
            return ["$element", "mnSidenav"];
        }

        $onInit() {
            this.service.initSideNav(this.id)
                .subscribe(status => this.toggle(status));
        }

        toggle(open) {
            if (_.isNil(open)) open = false;
            requestAnimationFrame(() => this.$element.toggleClass("open-sidenav", open));
        }

    }

    module.exports = {
        bindings: {
            id: "@",
        },
        controllerAs: "vm",
        controller: SideNavCtrl,
    }

})();