(function () {
    'use strict';

    const THEME_TEMPLATE = require('shared/views/style.tpl.html');

    class Palette {
        constructor(theme, palettes) {
            this.palettes = palettes;

            this.alpha = {};
            this.alpha.default = this.color(theme.name, theme.hues.default);
            this.alpha.hue1 = this.color(theme.name, theme.hues['hue-1']);
            this.alpha.hue2 = this.color(theme.name, theme.hues['hue-2']);
            this.alpha.hue3 = this.color(theme.name, theme.hues['hue-3']);

            this.default = this.alpha.default();
            this.hue1 = this.alpha.hue1();
            this.hue2 = this.alpha.hue2();
            this.hue3 = this.alpha.hue3();

            this.custom = this.color(theme.name);
        }

        color(name, hue) {
            if (_.isUndefined(hue)) return (hue, alpha) => this.rgba(name, hue, alpha);
            else return (alpha) => this.rgba(name, hue, alpha);
        }

        rgba(palette, hue, alpha = 1) {
            return `rgba(${this.palettes[palette][hue].value.join()}, ${alpha})`;
        }

        rgb(palette, hue) {
            return this.rgba(palette, hue);
        }
    }

    class ThemeGet {
        constructor(theme, palette, $interpolate, $q, frontDeskService, configService) {
            this.$q = $q;
            this.theme = theme;
            this.palette = palette;
            this.$interpolate = $interpolate;
            this.configService = configService;
            this.frontDeskService = frontDeskService;
        }

        run() {
            const deferred = this.$q.defer();
        
            this.$q.all([
                this.getVisitReasons(),
                this.getDoctorsStyles(),
                this.getTemplate(THEME_TEMPLATE),
            ]).then((data) => this.runSuccess(data, deferred), deferred.reject);

            return deferred.promise;
        }

        runSuccess(data, deferred) {
            this.generateStyle(data[2]);

            $("head").append(data[0]);
            $("head").append(data[1]);

            deferred.resolve(true);
        }

        getTemplate(template) {
            const deferred = this.$q.defer();

            if (_.isEmpty(template)) deferred.reject();
            else deferred.resolve(template);

            return deferred.promise;
        }

        generateStyle(template) {
            const primary = new Palette(this.theme.primary, this.palette);
            const style = {
                primary,
                rgb: (p, h) => primary.rgb(p, h),
                rgba: (p, h, a) => primary.rgba(p, h, a),
                warn: new Palette(this.theme.warn, this.palette),
                accent: new Palette(this.theme.accent, this.palette),
            };

            $("head").append(this.$interpolate(template.replace(/\n|\s{2,}/g, ''))(style));
        }

        getVisitReasons() {
            const deferred = this.$q.defer();
            this.frontDeskService.reasonSubject
                .subscribe(data => {
                    if (data.length > 0) success({data: data});
                    else this.frontDeskService.getReasons().then(success, deferred.reject);
                });

            function success(response) {
                const $style = _.reduce(response.data, (element, item) => {
                    return element.append(`
                        .mn-event.reason-${item.id}:not(.mn-list-item) {
                            background: ${item.color};
                            border-color: ${item.color}; 
                            color: ${item['is_dark'] ? '#000' : '#fff'};
                        }
                        
                        .mn-event.reason-${item.id}-entered:not(.mn-list-item) {
                            background: ${item.striped_color_css};
                            color: ${item['is_dark'] ? '#000' : '#fff'};
                        }
                        
                        .mn-list-item.reason-${item.id}-entered .mn-event-dot-container .fc-event-dot {
                            background: ${item.striped_color_css};
                        }
                    `);
                }, $('<style mn-reason-style/>'));

                deferred.resolve($style);
            }

            return deferred.promise;
        }

        refreshVisitReasons() {
            return this.getVisitReasons()
                .then(style => {
                    $('[mn-reason-style]').remove();
                    $('head').append(style);
                });
        }

        getDoctorsStyles() {
            const deferred = this.$q.defer();

            this.configService.get('doctors_colors')
                .then(success, deferred.reject);

            function success(data) {
                const $style = _.chain(data).compact().reduce((element, item) => {
                    let practiceStyle = `
                        .practice-list-item.physician-${item['staff_id']} .time-block, 
                        .practice-list-item.physician-${item['staff_id']} .md-button md-icon {
                            opacity: .8;
                            color: ${item.color};
                        }
                        
                        .practice-list-item.physician-${item['staff_id']} .md-button[disabled] md-icon {
                            opacity: .38;
                        }
                    `;

                    return element.append(`
                        .mn-event.physician-${item['staff_id']}:not(.mn-list-item) {
                            border-right: 5px ${item.color} solid !important;
                        }
                        
                        ${data.length > 0 ? practiceStyle : ''}
                    `);
                }, $('<style mn-physician-style/>')).value();

                deferred.resolve($style);
            }

            return deferred.promise;
        }
    }

    class ThemeService {
        constructor($mdThemingProvider) {
            this.theme = null;
            this.$get = _.noop;
            this.$get.$inject = ["$interpolate", "$q", "frontDeskService", "configService"];
            this.palettes = $mdThemingProvider._PALETTES;
        }

        static get $inject() {
            return ["$mdThemingProvider"];
        }

        setCurrentTheme(theme) {
            this.theme = theme;

            this.$get = function ($interpolate, $q, frontDeskService, configService) {
                return new ThemeGet(this.theme, this.palettes, $interpolate, $q, frontDeskService, configService);
            }
        }
    }

    module.exports = ThemeService;

})();
