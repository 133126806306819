(function () {
    'use strict';

    const {Workbox} = require('workbox-window');

    class ServiceWorkerRun {
        constructor($mdToast) {
            if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
                const wb = new Workbox('/service-worker.js');
                const toast = $mdToast.mnUpdateToast()
                    .label('code_base_update')
                    .hideDelay(15000);

                wb.register().then();
                wb.addEventListener('installed', event => {
                    if (event.isUpdate) $mdToast.show(toast);
                });
            }
        }

        static create() {
            return new ServiceWorkerRun(...arguments);
        }
    }

    ServiceWorkerRun.create.$inject = ['$mdToast'];

    module.exports = ServiceWorkerRun.create;

})();
