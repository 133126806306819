/**
 * Created by amine on 08/03/2018.
 */
(function () {
    'use strict';

    module.exports = CareSheetCtrl;

    CareSheetCtrl.$inject = ["careSheetService", "$mdDialog", "$translate", "organizationService"];

    function CareSheetCtrl(careSheetService, $mdDialog, $translate, organizationService) {
        let vm = this;

        vm.$onInit = init;
        vm.onReorder = onReorder;
        vm.onPaginate = onPaginate;
        vm.getData = getData;
        vm.deleteItem = deleteItem;
        vm.validateItem = validateItem;
        vm.invalidateItem = invalidateItem;

        function init() {
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }

            vm.columns = [];
            vm.options = [20, 15, 10, 5];
            vm.organizations = [];
            vm.sheets = [];
            vm.total = 0;
            vm.query = {
                limit: 10,
                page: 1,
                order: "-sheet_date",
            }

            organizationService.getOrganizations().then(success)

            function success(data) {
                vm.organizations = data;
                getData();
            }
        }

        function onReorder(order) {
            vm.query = _.assign(vm.query, {order: order});
            getData();
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function getData() {
            vm.tablePromise = careSheetService
                .getSheets(vm.query)
                .then(done);

            function done(data) {
                vm.total = data.length;
                vm.sheets = data.list;
            }
        }

        function deleteItem(sheet) {
            careSheetService.deleteCareSheet(sheet)
                .then(getData)
        }

        function validateItem(sheet) {
            $mdDialog
                .show(careSheetService.careSheetValidationDialog(true))
                .then(confirm, _.noop)

            function confirm() {
                careSheetService.validateCareSheet(sheet)
                    .then(getData)
            }
        }

        function invalidateItem(sheet) {
            $mdDialog
                .show(careSheetService.careSheetValidationDialog(false))
                .then(confirm, _.noop)

            function confirm() {
                careSheetService.invalidateCareSheet(sheet)
                    .then(getData)
            }
        }
    }

})();