/**
 * Created by BETALOS on 21/01/2017.
 */
(function () {

    'use strict';

    class DialogCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        hide(value) {
            this.dialog.hide(value);
        }

        abort() {
            this.dialog.cancel();
        }

        refresh() {
            location.reload();
        }
    }

    const mnPromptDialog = {
        multiple: true,
        controllerAs: "vm",
        escapeToClose: false,
        controller: DialogCtrl,
        bindToController: true,
        clickOutsideToClose: false,
        template: require("shared/views/prompt-dialog.tpl.html"),
    };

    const mnConnectionLostDialog = {
        multiple: true,
        controllerAs: "vm",
        parent: $(document.body),
        escapeToClose: false,
        bindToController: true,
        clickOutsideToClose: false,
        controller: DialogCtrl,
        template: `
            <md-dialog aria-label="connection lost" class="connection-lost-dialog">
                <md-dialog-content class="layout-column layout-align-center-center">
                    <h1 translate-once="connection_lost_with_server"></h1>
                    <md-button ng-click="vm.refresh()">
                        <md-icon md-font-icon="mdi-refresh" md-font-set="mdi"></md-icon>
                        <span translate-once="connection_lost_refresh"></span>
                    </md-button>
                </md-dialog-content>
            </md-dialog>
        `,
    };

    class DialogConfig {
        constructor($mdDialogProvider) {
            $mdDialogProvider.addPreset('mnPromptDialog', {
                options: () => mnPromptDialog,
                methods: ['title', 'event', 'placeholder', 'query', 'result'],
            });

            $mdDialogProvider.addPreset('mnConnectionLostDialog', {
                methods: [], options: () => mnConnectionLostDialog
            });
        }

        static create() {
            return new DialogConfig(...arguments);
        }
    }

    DialogConfig.create.$inject = ["$mdDialogProvider"];

    module.exports = DialogConfig.create;


})();
