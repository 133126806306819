/**
 * Created by Hp on 09/05/2017.
 */


(function () {
    'use strict';
    const angular = require('angular');
    const CamCtrl = require('./controllers/CamCtrl');
    const EquipmentCtrl = require('./controllers/EquipmentCtrl');
    const InterventionCtrl = require('./controllers/InterventionCtrl');
    const EquipmentFormCtrl = require('./controllers/EquipmentFormCtrl');
    const InterventionFormCtrl = require('./controllers/InterventionFormCtrl');
    const CamConfigCtrl = require('./controllers/CamConfigCtrl');
    const CamConfigItemCtrl = require('./controllers/CamConfigItemCtrl');
    const CamDefaultConfigCtrl = require('./controllers/CamDefaultConfigCtrl');

    const interventionSteps = require('./components/interventionSteps');

    const equipmentService = require('./services/equipmentService');
    const interventionService = require('./services/interventionService');
    const camConfigService = require('./services/camConfigService');

    const mnUniqueness = require('./directives/uniquness');


    angular
        .module("medinet")
        .controller('CamCtrl', CamCtrl)
        .controller('EquipmentCtrl', EquipmentCtrl)
        .controller('EquipmentFormCtrl', EquipmentFormCtrl)
        .controller('InterventionCtrl', InterventionCtrl)
        .controller('InterventionFormCtrl', InterventionFormCtrl)
        .controller('CamConfigCtrl', CamConfigCtrl)
        .controller('CamConfigItemCtrl', CamConfigItemCtrl)
        .controller('CamDefaultConfigCtrl', CamDefaultConfigCtrl)

        .directive('mnUniqueness', mnUniqueness)

        .component('interventionSteps', interventionSteps)

        .service('equipmentService', equipmentService)
        .service('interventionService', interventionService)
        .service('camConfigService', camConfigService)

})();