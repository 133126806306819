/**
 * Created by Hp on 03/08/2017.
 */

(function () {
    'use strict';
    module.exports = QuotationFormCtrl;

    QuotationFormCtrl.$inject = ["dragulaService", "$stateParams", "patientService", "$translate", "system", "$mdDialog", 'billingService', "saleService", "$state", "$mdToast", "$scope"];

    function QuotationFormCtrl(dragulaService, $stateParams, patientService, $translate, system, $mdDialog, billingService, saleService, $state, $mdToast, $scope) {
        var vm = this;
        var currentQuotationId = $stateParams['quotationId'];

        vm.$onInit = init;
        var dateFormat = system['date_format'].js;
        var currentVisit = $stateParams['visit'];
        var currentBeneficiary = $stateParams['beneficiary'];
        var currentBeneficiaryType = $stateParams['beneficiaryType'];

        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;
        vm.onPaginate = onPaginate;

        vm.SelectVisit = SelectVisit;
        vm.removeLine = removeLine;
        vm.quotationTotal = quotationTotal;
        vm.getSubTotal = getSubTotal;
        vm.selectArticle = selectArticle;
        vm.addEmptyLine = addEmptyLine;
        vm.submit = submit;
        vm.validateQuotation = validateQuotation;
        vm.invalidateQuotation = invalidateQuotation;
        vm.removeQuotation = removeQuotation;
        vm.cancel = goBack;
        vm.showSubLines = showSubLines;
        vm.prePrint = prePrint;

        vm.SelectProcedure = SelectProcedure;
        $scope.$watch('vm.quotation.is_valid', readOnlyQuotation);
        var bag = "line-bag";

        function init() {
            vm.selectedLine = null;
            vm.quotation_details = [];
            vm.quotation = {};
            vm.lines = [];
            vm.mnModel = 'Quotation';

            vm.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};
            vm.is_new = !_.isNil(currentQuotationId) ? false : true;
            vm.query = {
                limit: 5,
                page: 1,
                order: "-id"
            }
            vm.options = [5, 10, 20, 100];
            vm.paginationLabel = {
                page: $translate.instant('page'),
                rowsPerPage: $translate.instant('rowsPerPage'),
                of: $translate.instant('of')
            }
            if (!_.isNil(currentQuotationId)) {
                vm.promise = billingService.getQuotation(currentQuotationId).then(success);
            }
            else {
                vm.promise = null;
                vm.quotation = {
                    date: moment().format(dateFormat),
                    beneficiary: currentBeneficiary || null,
                    beneficiary_type: currentBeneficiaryType || 'PATIENT',
                    lines: [],
                    deadline: "1"
                };
                getData();
                loadVisit()
            }
            initDrag();

            function success(data) {
                vm.quotation = data;
            }
        }

        function loadVisit() {
            if (currentVisit) {
                _.forEach(currentVisit.procedures, function (procedure) {
                    loadProcedure(procedure);
                });
            }
        }

        function readOnlyQuotation() {
            vm.readOnly = (vm.quotation && vm.quotation.is_valid) ? vm.quotation.is_valid : false;
        }

        function removePatient() {
            vm.quotation.beneficiary = null;
        }

        function initDrag() {
            dragulaService.options($scope, bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
            var drake = dragulaService.find($scope, bag).drake;
            drake.on("dragend", _.mnDelay(function () {
            }, 400));
        }

        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id, false)
                .then(doneCallback);

            function doneCallback(data) {
                vm.quotation.beneficiary = data;
            }
        }

        function onPaginate(page, limit) {
            vm.query = _.assign(vm.query, {page: page, limit: limit});
            getData();
        }

        function getData() {
            if (vm.quotation.lines.length < ((vm.query.page - 1) * vm.query.limit)) {
                vm.query.page = 1;
            }
        }

        function SelectVisit(ev) {
            $mdDialog.show(_.assign(require('billing/dialogs/visit-search-dialog'), {
                targetEvent: ev,
                locals: {
                    beneficiary: vm.quotation.beneficiary,
                    beneficiary_type: vm.quotation['beneficiary_type']

                }
            })).then(function doneCallBack(data) {
                _.forEach(data, function (visit) {
                    _.forEach(visit.procedures, function (procedure) {
                        loadProcedure(procedure);
                    });
                })
            });
            getData();
        }

        function loadProcedure(procedure) {
            if (_.isNil(_.find(vm.quotation.lines, {code: !_.isNil(procedure.procedure) ? procedure.procedure.code : procedure.code}))) {
                vm.quotation.lines.push({
                    code: !_.isNil(procedure.procedure) ? procedure.procedure.code : procedure.name,
                    description: procedure.name,
                    qte: procedure.qte,
                    price: procedure.price,
                    procedure_uid: procedure.uid,
                });
            }

        }

        function removeLine(index) {
            vm.quotation.lines.splice((vm.query.page - 1) * vm.query.limit + index, 1);
        }

        function showSubLines(index) {
            if (vm.selectedLine == index) {
                vm.selectedLine = -1;
            } else {
                vm.selectedLine = index;
            }
        }

        function selectArticleCallBack(data) {
            _.forEach(data, function (article) {
                if (_.isNil(_.find(vm.quotation.lines, {code: article.code}))) {
                    vm.quotation.lines.push({
                        code: article.code,
                        description: article.short_desc,
                        qte: 1,
                        price: article.sale_price,
                        article: _.cloneDeep(article)
                    });
                }
            });

        }

        function selectArticle(ev) {
            $mdDialog.show(_.assign(require('stock/dialogs/article-search-dialog'), {
                targetEvent: ev,
                locals: {
                    search: {interdict_sale: {$ne: true}, is_deleted: {$ne: true}}
                }
            })).then(selectArticleCallBack);
        }

        function addEmptyLine() {
            vm.quotation.lines.push({is_comment: true});
        }

        function quotationTotal() {
            return vm.quotation.taxed_amount = _.sumBy(vm.quotation.lines, function (line) {
                if (!line.is_comment) return line.price * line.qte;
            });
        }

        function assignBeneficiaryModel() {
            if (vm.quotation['beneficiary_type'] === 'PATIENT') vm.quotation.beneficiary = _.assign(vm.quotation.beneficiary, {
                _module: "patient.models",
                _model: "Patient"
            });
            if (vm.quotation['beneficiary_type'] === 'ORGANIZATION') vm.quotation.beneficiary = _.assign(vm.quotation.beneficiary, {
                _module: "shared.insurance.models",
                _model: "Organization"
            });
        }

        function submit(with_cancel) {
            if (!vm.prevent_save) {
                vm.prevent_save = true;
                assignBeneficiaryModel();
                vm.promise = billingService.saveQuotation(vm.quotation).then(success);
            }

            function success(data) {
                vm.prevent_save = false;
                if (with_cancel) {
                    goBack();
                }
                else {
                    saveSuccess(data);
                }
            }
        }

        function saveSuccess(data) {
            vm.quotation = data;
            if (_.isNil(currentQuotationId)) $state.go('app.billing.quotation-form', {quotationId: data.id}, {location: 'replace'});
            vm.prevent_invalidate = false;
            vm.prevent_validate = false;
        }

        function validateQuotation() {
            if (!vm.prevent_validate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('validation_warning'))
                        .ariaLabel('validation_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    vm.prevent_validate = true;
                    billingService.updateBillingDocument(vm.quotation.id, 'quotation', {is_valid: true}).then(saveSuccess);
                })
            }
        }

        function invalidateQuotation() {
            if (!vm.prevent_invalidate) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .parent($(document.body))
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant('invalidate_warning'))
                        .ariaLabel('invalidate_warning')
                        .ok($translate.instant('confirm'))
                        .cancel($translate.instant('cancel'))
                ).then(function () {
                    vm.prevent_invalidate = true;
                    vm.promise = billingService.invalidateQuotation(vm.quotation.id).then(doneCallBack);
                });
            }

            function doneCallBack(data) {
                $state.go('app.quotation-form', {quotationId: data.id}, {location: 'replace'});
                vm.quotation = data;
            }
        }

        function removeQuotation(quotation) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_warning'))
                    .ariaLabel('delete_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                if (!vm.prevent_remove) {
                    vm.prevent_remove = true;
                    vm.promise = billingService.deleteQuotation(quotation.id).then(success);
                }
            });

            function success(data) {
                goBack();
            }
        }

        function goBack() {
            $state.go('app.billing.quotation');
        }

        function getSubTotal(line) {
            return (line.price || 0.0) * (line.qte || 0.0);
        }

        function SelectProcedure(data) {
            _.forEach(data, function (procedure) {
                if (_.isNil(_.find(vm.quotation.lines, {code: procedure.code}))) {
                    vm.quotation.lines.push({
                        code: procedure.code || procedure.name,
                        description: procedure.name,
                        qte: 1,
                        price: procedure.price,
                        procedure_uid: procedure.uid,
                    });
                }
            });
        }

        function prePrint() {
            assignBeneficiaryModel();
            return billingService.saveQuotation(vm.quotation);
        }
    }
})();