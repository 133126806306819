(function () {

    'use strict';

    class CamDefaultConfigCtrl {
        constructor($translate) {
            this.$translate = $translate;
        }

        static get $inject() {
            return ["$translate"];
        }

        $onInit() {
            const colorPickerParams = require('../../parameters/json/color-picker-config.json');

            this.picker_options = _.assign(colorPickerParams, {label: this.$translate['instant']('color')});

        }
    }

    module.exports = CamDefaultConfigCtrl;
})();