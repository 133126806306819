(function () {

    'use strict';

    class AsyncSubmit {
        constructor($timeout, $parse) {
            this.restrict = "A"
            this.$parse = $parse;
            this.$timeout = $timeout;
        }

        static create() {
            return new AsyncSubmit(...arguments);
        }

        link(scope, element, attrs) {
            const fn = this.$parse(attrs['asyncSubmit']);

            element.on('submit', event => {
                const submitElement = $('[type=submit], [async-click]', element);

                submitElement.attr('disabled', true);

                scope.$apply(() => {
                    const call = fn(scope, {$event: event});

                    if (this.isPromise(call)) call
                        .catch(_.noop)
                        .finally(() => this.unDisable(submitElement));
                    else this.$timeout(() => this.unDisable(submitElement), 200);
                });
            });
        }

        unDisable(element) {
            element.prop('disabled', false);
        }

        isPromise(value) {
            return Boolean(value && typeof value.then === 'function');
        }
    }

    AsyncSubmit.create.$inject = ['$timeout', '$parse'];

    module.exports = AsyncSubmit.create;

})();