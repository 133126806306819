/**
 * Created by amine on 01/12/2018.
 */


(function () {
    'use strict';
    const VISUALIZE_FILE = require('stand-alone/file-manager/dialogs/visualize-file-dialog');
    class PatientRelationsCtrl {
        constructor($http, $scope, $mdDialog) {

            this.$http = $http;
            this.$scope = $scope;
            this.dialog = $mdDialog;
            this.relationTypes = [
                {value: 'PARENT', label: "patient_relations_parent", index: 0},
                {value: 'SPOUSE', label: "patient_relations_spouse", index: 1},
                {value: 'CHILD', label: "patient_relations_child", index: 2},
                {value: 'SIBLING', label: "patient_relations_sibling", index: 3},
                {value: 'NURSE', label: "patient_relations_nurse", index: 4},
            ];
        }

        static get $inject() {
            return ["$http", "$scope", "$mdDialog"];
        }

        $onInit() {
        }

        getRelationLabel(relation) {

            return _.get(_.find(this.relationTypes, {value: relation}), 'label', relation);
        }

        viewImage(ev, _pk) {
            const file = {name: '', mime: 'image/png', url: `/api/patient/${_pk}/data/`};

            const dialog = _.assign({}, VISUALIZE_FILE, {
                targetEvent: ev,
                locals: {files: _.castArray(file), fileIndex: 0, allowEdit: false}
            });

            this.dialog.show(dialog);
        }

    }

    module.exports = {
        controllerAs: "vm",
        bindings: {
            relations: "=relations"
        },
        template: require('billing/views/patient-relations.tpl.html'),
        controller: PatientRelationsCtrl,
    };

})();
